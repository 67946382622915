import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from 'pages/Dashboard';
import OrganizationList from 'pages/Organizations';
import UsersList from 'pages/Users';
import GroupList from 'pages/Groups';
import { NotFound } from 'navigation/NotFound';
import { Page1 } from 'pages/Page1';
import Login from 'pages/Login';
import Privacy from 'pages/Privacy';
import { AuthorizedPage1 } from 'pages/AuthorizedPage1';
import PrivateRoute from './Auth/PrivateRoute';
import ViewOrganization from 'pages/Organizations/ViewOrganization';
import ViewSystemUser from '@pages/Users/SystemUsers/ViewSystemUser';
import { DeleteAccount } from '@pages/DeleteAccount';
import ViewOrganizationUsers from '@pages/Users/OrganizationUsers/ViewOrganizationUsers';
import { ListDetailJobTitle } from 'pages/JobTitle/Details/ListDetailJobTitle';
import { ListDetailGroup } from 'pages/Groups/Details/ListDetailGroup';
import GeneralView from 'pages/JobScheduler';
import { ViewEmployeeUser } from '@pages/Users/EmployeeUsers/ViewEmployeeUser';
import { TimeSheet } from '@pages/TimeSheet';
import { Profile } from '@pages/Profile';
import ChooseAvatarForm from 'pages/Login/ChooseAvatarForm';
import { Lobby } from '@pages/Lobby';
import { TimeSheetLobby } from '@pages/TimeSheetLobby';
import { MessageCenter } from '@pages/MessageCenter';
import { SettingsCenter } from '@pages/SettingsCenter';

import {
  ROOT,
  GROPUS,
  DASHBOARD,
  PAGE1,
  AUTH_PAGE1,
  ORGANIZATIONS,
  VIEW_ORGANIZATIONS,
  USERS,
  VIEW_JOB_TITLES,
  VIEW_USER_SYSTEM,
  VIEW_USER_ORGANIZATION,
  VIEW_GROUP,
  VIEW_USER_EMPLOYEE,
  VIEW_SITE,
  PROFILE,
  CHOOSEAVATAR,
  JOB_SCHEDULER,
  ORGANIZATIONS_MANAGEMENT,
  SITES,
  LOBBY,
  TIME_SHEET_LOBBY,
  TIME_SHEET,
  MESSAGE_CENTER,
  SETTINGS,
  MONITORING_LAST_ARRIVAL
} from 'navigation/CONSTANTS';
import { OrganizationManagement } from 'pages/OrganizationManagement';
import { ViewSite } from 'pages/Sites/Details';
import MonitoringList from 'pages/Monitoring';

export const RouterConfig = () => {
  return (
    <Switch>
      {/* List all public routes here */}
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/privacy">
        <Privacy />
      </Route>
      <Route path="/delete-account">
        <DeleteAccount />
      </Route>
      <Route path={[CHOOSEAVATAR]}>
        <ChooseAvatarForm />
      </Route>
      <PrivateRoute path={[VIEW_ORGANIZATIONS]} exact>
        <ViewOrganization />
      </PrivateRoute>
      {/* List all private/auth routes here */}
      <PrivateRoute path={AUTH_PAGE1} exact>
        <AuthorizedPage1 />
      </PrivateRoute>
      <PrivateRoute path={ROOT} exact>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path={DASHBOARD} exact>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute path={JOB_SCHEDULER} exact>
        <GeneralView />
      </PrivateRoute>
      <PrivateRoute path={PAGE1} exact>
        <Page1 />
      </PrivateRoute>
      <PrivateRoute path={ORGANIZATIONS} exact>
        <OrganizationList />
      </PrivateRoute>
      <PrivateRoute path={MONITORING_LAST_ARRIVAL} exact>
        <MonitoringList />
      </PrivateRoute>
      <PrivateRoute path={USERS} exact>
        <UsersList />
      </PrivateRoute>
      <PrivateRoute path={GROPUS} exact>
        <GroupList />
      </PrivateRoute>
      <PrivateRoute path={VIEW_JOB_TITLES} exact>
        <ListDetailJobTitle />
      </PrivateRoute>
      <PrivateRoute path={VIEW_USER_SYSTEM} exact>
        <ViewSystemUser />
      </PrivateRoute>
      <PrivateRoute path={VIEW_GROUP} exact>
        <ListDetailGroup />
      </PrivateRoute>
      <PrivateRoute path={VIEW_USER_ORGANIZATION} exact>
        <ViewOrganizationUsers />
      </PrivateRoute>
      <PrivateRoute path={VIEW_USER_EMPLOYEE} exact>
        <ViewEmployeeUser />
      </PrivateRoute>
      <PrivateRoute path={VIEW_SITE} exact>
        <ViewSite />
      </PrivateRoute>
      <PrivateRoute path={PROFILE} exact>
        <Profile />
      </PrivateRoute>
      <PrivateRoute path={ORGANIZATIONS_MANAGEMENT} exact>
        <OrganizationManagement />
      </PrivateRoute>
      <PrivateRoute path={SITES} exact>
        <OrganizationManagement />
      </PrivateRoute>
      <PrivateRoute path={LOBBY} exact>
        <Lobby />
      </PrivateRoute>
      <PrivateRoute path={TIME_SHEET_LOBBY} exact>
        <TimeSheetLobby destination={TIME_SHEET_LOBBY} />
      </PrivateRoute>
      <PrivateRoute path={TIME_SHEET} exact>
        <TimeSheet />
      </PrivateRoute>
      <PrivateRoute path={MESSAGE_CENTER} exact>
        <MessageCenter />
      </PrivateRoute>
      <PrivateRoute path={SETTINGS} exact>
        <SettingsCenter />
      </PrivateRoute>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};
