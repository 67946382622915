import React, { useState, useEffect, useRef } from 'react';
import EncList from '@components/EncList';
import moment from 'moment';
import {
  AppTable,
  AppButton,
  AppDropDownSingleSelect,
  AppDropDownCheckBoxSelect,
  AppModal,
  CustomizedSnackbar,
  AppComponentLoading,
  IcArrow,
  InputForm,
  ShiftNestedCell,
  AppMassPublishShiftButton,
  AppDarkTooltip,
} from '@components';
import Calender from '@assets/icons/Calender.svg';
import { RequestQueryBuilder, CondOperator } from '@nestjsx/crud-request';
import { setReloadSchedulerAction } from '@redux/actions/appActions';
import { JOB_SCHEDULER, LOBBY } from '@navigation/CONSTANTS';
import { USERS_API, SHIFTS_API } from '@services/CONSTANTS';
import { useHistory, useParams } from 'react-router-dom';
//import status_active from '@assets/icons/status_active.svg';
import { useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import styled from 'styled-components';
import currentWeekNumber from 'current-week-number';
import { getEntityData } from '@services';
import { PALETTE_COLOR, SHIFT_CREATION, SHIFT_EDIT } from '@config/CONSTANTS';
import { formatEspecial } from '@utils/dateFormatHelper';
import { ShiftTimes } from 'utils/appUtils';
import Ellipse from '@assets/icons/Ellipse.svg';
import Down from '@assets/icons/Down.svg';
import { Formik } from 'formik';
import { Menu, MenuItem } from '@szhsin/react-menu';
import ICAddButton from '@assets/icons/ic_float_add_button.svg';
import ic_on_the_way from '@assets/images/onTheWay.png';
import { useDispatch } from 'react-redux';
import { setJobSchedulerGroupAction } from '@redux/actions/appActions';
import { size } from 'lodash';
import { calcAvatar } from 'utils/avatar';
//import Draft from '@assets/icons/Draft.svg';
import { Calendar } from './Calendar';
import { Activity } from './Activity';
import { ShiftForm } from './Shifts/ShiftForm';
import { ViewTemplates } from './Shifts/ViewTemplates';
import { ShiftFullTimeDifferences } from 'utils/appUtils';
import chroma from 'chroma-js';
import chain from '@assets/icons/chain.svg';
import { Col, Container, Row } from 'react-bootstrap';
//Status icons
import status_draft from '@assets/icons/status_draft.svg';
import ic_claimed from '@assets/icons/ic_claimed.svg';
import ic_pending_approval from '@assets/icons/ic_pending_approval.svg';
import ic_assigned from '@assets/icons/ic_assigned.svg';
import ic_cancellation_request from '@assets/icons/ic_cancellation_request.svg';
import ic_canceled from '@assets/icons/ic_canceled.svg';
import ic_no_show from '@assets/icons/ic_no_show.svg';
import ic_unsiged_hours from '@assets/icons/ic_unsiged_hours.svg';
import ic_completed_and_signed from '@assets/icons/ic_completed_and_signed.svg';
import ic_running_late from '@assets/icons/ic_running_late.svg';
import ic_clock_in from '@assets/icons/ic_clock_in.svg';
import ic_published from '@assets/icons/ic_published.svg';
import ic_search from '@assets/icons/ic_search.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import ic_arrow_left_enabled from '@assets/icons/ic_arrow_left_enabled.svg';
import ic_arrow_left from '@assets/icons/ic_arrow_left.svg';
import ic_arrow_right_enabled from '@assets/icons/ic_arrow_right_enabled.svg';
import ic_arrow_right from '@assets/icons/ic_arrow_right.svg';
//End status icons
import ic_eye from '@assets/icons/ic_eye.svg';
import { showMessageAction } from '@redux/actions/appActions';
import { useTranslation } from 'react-i18next';
import ic_arrow_down from '@assets/icons/ic_arrow_down.svg';
import i18next from 'i18n';
import { DEFAULT_PAGINATION_RESULTS_OPTIONS } from '@config';
import { EmployeeShiftView, GlobalView } from './ShiftView';
i18next.changeLanguage(i18next.resolvedLanguage);

const PaginationTopButton = styled.div`
  border: 1px solid #e7ecf4;
  box-sizing: border-box;
  border-radius: 20px;
  width: max-content;
  height: 40px;
  padding: 8px 10px 9px 10px;
  display: flex;
  font-size: 14px;
`;

const PageLabelWrapper = styled.div`
  margin-left: 4px;
  margin-right: 4px;
`;
const AdvanceFilterButtonWrapper = styled.div`
  font-size: 14px;
  border: 1px solid #e7ecf4;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 8px 10px 8px 15px;
  display: flex;
  cursor: pointer;
  color: #1179bf;
`;

const StyledNestedCell = styled.div`
  align-items: center;
  font-size: 14px;
  padding: 0px 15px;
  border-radius: 10px;
  min-height: 46px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Status = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

const OnTheWay = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 0.5rem;
  cursor: pointer;
`;

const StatusContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const StyleFilter = styled.div`
  display: flex;
  font-weight: 275;
  font-size: 23px;
  line-height: 34px;
  width: max-content;
`;

const BottomWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #edf1f8;
  border-radius: 0px 0px 16px 16px;
  padding: 20px;
`
const ResultsWrapper = styled.div`
  font-size: 14px;
  border: 1px solid #e7ecf4;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 8px 10px 8px 15px;
  width: 100%;
  display: flex;
  cursor: pointer;
`;



const StyleInternalColor = styled.div`
  border: 1px solid ${(props) => (props.color ? chroma(props.color).darken().hex() : 'white')};
  background-color: ${(props) => (props.color ? props.color : 'transparent')};
  border-radius: 50%;
  box-sizing: border-box;
  width: 13px;
  height: 13px;
  margin: 0px 3px 0px 0px;
`;

const StyleColor = styled.div`
  background: #ffffff;
  border: 1px solid #e7ecf4;
  box-sizing: border-box;
  border-radius: 20px;
  width: max-content;
  min-width: 20px;
  height: 26px;
  margin: 5px 6px;
  display: flex;
  padding: 5px 5px 9px 5px;
`;

const TittleFilter = styled.div`
  margin-right: 15px;
  width: max-content;
`;

const FilterIcon = styled.img`
  margin-right: 11px;
`;


const PaginationTopButtonSeparator = styled.div`
  background: #e7ecf4;
  width: 1px;
  height: 24px;
  margin-left: 12px;
  margin-right: 12px;
`;

const StyledLabel = styled.div`
  margin-left: 4px;
  padding: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
`;

const StyledIconLabel = styled.div`
  padding: 1px 8px;
`;

const DropDownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
`;

const FloatImg = styled.img`
  position: fixed;
  width: '100%';
  bottom: 20px;
  right: 30px;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 100;
`;

const Icon = styled.img``;

const ViewIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const DEFAULT_JOINS = [
  { field: 'organization' },
  { field: 'status' },
  { field: 'site' },
  { field: 'user_shifts' },
  { field: 'user_shifts.user' },
  { field: 'user_shifts.status' },
  { field: 'jobs_titles' },
  { field: 'jobs_titles.jobtitle' },
  { field: 'timeZone' },
  { field: 'repeat_shift_option' },
  { field: 'user_shifts.userShiftEvents' },
  { field: 'user_shifts.userShiftEvents.type' },
  { field: 'user_shifts.user_shifts_requests' },
  { field: 'user_shifts.user_shifts_requests.status' },
];

export const GeneralView = () => {
  const { t } = useTranslation();
  let { type } = useParams();
  const dispatch = useDispatch();
  const { userData, jobSchedulerGroup, valuesDefinitions, reloadScheduler, userPermissions } =
    useSelector((state) => state.app);
  const [, setFilterDates] = useState({
    start: moment.utc().startOf('week'),
    end: moment.utc().endOf('week'),
  });
  const [showSucessModal, setShowSucessModal] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  const statuses =
    valuesDefinitions &&
    valuesDefinitions.filter((v) => v.definitionType.definition_type === 'SHIFT_STATUS');
  const fullAccessPermission =
    valuesDefinitions &&
    valuesDefinitions.filter(
      (v) =>
        v.definitionType.definition_type === 'USERS_GROUPS_PERMISSION' &&
        v.value_definition === '020',
    )[0];
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDatesListView, setFilterDatesListView] = useState('Week');
  const [conditionDatesListView, setConditionDatesListView] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [currentJoins, setCurrentJoins] = useState(DEFAULT_JOINS);
  const [labelDatesListView, setLabelDatesListView] = useState(formatEspecial(new Date(), 'MMM D'));
  const [weekList, setWeekList] = useState(currentWeekNumber(new Date()));
  const [yearList, setYearList] = useState(formatEspecial(new Date(), 'YYYY'));
  const [dayList, setDayList] = useState(new Date());
  const [viewListView, setViewListView] = useState('By Date');
  const viewListBy = useRef('By Date');
  // const [viewType, setViewType] = React.useState(type ? type : 'calendar');
  const [viewType, setViewType] = React.useState(type ? type : 'list-view');
  const [groupsList, setGroupsList] = useState([]);
  const [dataListView, setDataListView] = useState([]);
  const [sitesList, setSitesList] = useState([]);
  const [selectedSitesList, setSelectedSitesList] = useState([]);
  const [colorList, setColorList] = useState([]);
  let history = useHistory();
  const [openCreateSnglShift, setOpenCreateSnglShift] = useState(false);
  const [openTemplates, setOpenTemplates] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [loadingGroups, setLoadingGroups] = useState(true);
  const [loadingSites, setLoadingSites] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [draft, setDraft] = useState(false);
  const [shiftModalTitle, setShiftModalTitle] = useState('');
  let shiftAction = useRef('');
  const selectedShift = useRef('');
  //Determine if logged user has the permissions to make some actions
  const [isUserPermissionJS, setIsUserPermissionJS] = useState(false);
  // Const to handle the view detail modal
  const [openShiftDetail, setOpenShiftDetail] = React.useState(false);
  const [user, setUser] = React.useState({});
  const [originShift, setOriginShift] = useState({});
  const [shift, setShift] = React.useState({});
  const [avataraShiftDetail, setAvataraShiftDetail] = React.useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(['1-10']);
  const [currentPage, setCurrentPage] = useState('1-10');
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  if (!jobSchedulerGroup || !jobSchedulerGroup.id) {
    history.push(LOBBY);
  }
  const ArrowWrapper = styled.img`
  width: 20px;
  height: 22px;
`;

  const calcPages = React.useCallback(
    async (pageCount, total) => {
      const newPages = [];
      for (let index = 0; index < pageCount; index++) {
        if (index + 1 === pageCount) {
          newPages.push(`${index * limit + 1}-${total}`);
        } else {
          newPages.push(`${index * limit + 1}-${(index + 1) * limit}`);
        }
      }
      setPages(newPages);
    },
    [limit],
  );
  //
  const handleShiftDetail = (shift) => {
    // shift.current = shift;
    consultAvatar(shift.creator_user_id);
    setOpenShiftDetail((prev) => {
      return !prev;
    });
  };

  const showMessage = React.useCallback(
    async (newMessage) => {
      dispatch(showMessageAction(newMessage));
    },
    [dispatch],
  );

  /***
   * Get info from a specific shift
   */
  const consultShift = (idShift, user) => {
    try {
      // setIsLoading(true);
      const qb = RequestQueryBuilder.create()
        .setJoin({ field: 'organization' })
        .setJoin({ field: 'jobs_titles' })
        .setJoin({ field: 'jobs_titles.jobtitle' })
        .setJoin({ field: 'status' })
        .setJoin({ field: 'site' })
        .setJoin({ field: 'user_shifts.userShiftEvents' })
        .setJoin({ field: 'user_shifts.userShiftEvents.type' })
        .setJoin({ field: 'user_shifts.user_shifts_requests' })
        .setJoin({ field: 'user_shifts.user_shifts_requests.status' })
        .setLimit(1);

      getEntityData(`${SHIFTS_API}${idShift}`, qb.query()).then((res) => {
        //Validates if shift did not change for another user
        if (user === null || user?.id === null) {
          //Shift card from first row
          setOriginShift(res);
          setOpenShiftDetail(true);
          setIsLoading(false);
        } else if (
          res?.user_shifts?.length > 0 &&
          res?.user_shifts?.find((us) => us.user.id === user.id)
        ) {
          setOriginShift(res);
          setOpenShiftDetail(true);
          setIsLoading(false);
        } else {
          //No data, shift or user_shift was deleted
          setIsLoading(false);
          setOpenShiftDetail(false);
          //Reload calendar
          reloadSchedulerCalendar(true);
          //Message
          showMessage({
            show: true,
            type: 'success',
            title: `${i18next.t('success_messages.shift_job_scheduler_status_changed')}`,
            message: '',
          });
        }
      });
    } catch (error) {
      console.error('in userServices > getUserDetails1, Err===', error);
      setIsLoading(false);
    }
  };

  const obtainUserShiftEmployee = (shift, user) => {
    const response =
      shift.user_shifts &&
      shift.user_shifts.length > 0 &&
      shift.user_shifts.filter((us) => us.user.id === user.id);
    return response.length > 0 ? response[0] : null;
  };

  const consultAvatar = (subCogId) => {
    try {
      const qb = RequestQueryBuilder.create()
        .setFilter({ field: 'sub_cognito_id', operator: CondOperator.EQUALS, value: subCogId })
        .setLimit(1);

      getEntityData(USERS_API, qb.query()).then((res) => {
        let userData = res[0];
        setAvataraShiftDetail(userData);
      });
    } catch (error) {
      console.error('in userServices > getUserDetails1, Err===', error);
    }
  };

  const handleClickOpenCreateSnglShiftModal = (action, item = null, draft = false) => {
    shiftAction.current = action;
    if (action === SHIFT_CREATION)
      setShiftModalTitle(`${t('JobScheduler.modals.add_new_shift_title')}`);
    else if (action === SHIFT_EDIT) {
      item.status.value_definition === '020'
        ? setShiftModalTitle(`${t('JobScheduler.modals.draft_shift_title')}`)
        : setShiftModalTitle(`${t('JobScheduler.modals.edit_shift_title')}`);
    }
    selectedShift.current = item?.id;
    setDraft(draft);
    setOpenCreateSnglShift(true);
  };

  const handleClickCloseTemplatesModal = () => {
    setOpenTemplates(false);
  };
  const handleCloseCreateSnglShiftModal = () => {
    setOpenCreateSnglShift(false);
  };

  const handleChangeTypeURL = (type) => {
    if (type === 'calendar') {
      history.push(JOB_SCHEDULER.replace(':type', 'calendar'));
    } else if (type === 'list-view') {
      history.push(JOB_SCHEDULER.replace(':type', 'list-view'));
    } else if (type === 'activity') {
      history.push(JOB_SCHEDULER.replace(':type', 'activity'));
    } else {
      history.push(JOB_SCHEDULER.replace(':type', 'calendar'));
    }
  };

  /***
   * To know which user type is and what button activate
   */
  const getActiveTabIndex = (type) => {
    // if (type === 'calendar') {
    //   return 0;
    // } else if (type === 'list-view') {
    //   return 1;
    // } else if (type === 'activity') {
    //   return 2;
    // } else {
    //   return 0;
    // }
    if (type === 'list-view') {
      return 0;
    } else if (type === 'activity') {
      return 1;
    } else {
      return 0;
    }
  };

  const setSelectedGroup = React.useCallback(
    async (group) => {
      dispatch(setJobSchedulerGroupAction(group));
    },
    [dispatch],
  );

  const reloadSchedulerCalendar = React.useCallback(
    async (isLoading) => {
      dispatch(setReloadSchedulerAction(isLoading));
    },
    [dispatch],
  );

  /***
   * Calc buttons visibility based on role and its permission (only view and & full access)
   */
  useEffect(() => {
    if (userData?.isSuperAdmin) {
      //Can do averything
      setIsUserPermissionJS(true);
    } else if (userData?.isGroupAdmin) {
      //Depends on group permission
      if (userData?.isGroupAdmin) {
        const currentGroupPermission = userPermissions?.systemUserGroups?.find(
          (sg) => sg.id === jobSchedulerGroup.id,
        );
        if (
          currentGroupPermission &&
          currentGroupPermission.permission === fullAccessPermission?.value_definition
        ) {
          setIsUserPermissionJS(true);
        }
      }
    } else if (userData?.isSiteManager || userData?.isOrganizationRepresentative) {
      //Depends on user permission
      if (fullAccessPermission?.value_definition === userData?.permission.value_definition) {
        setIsUserPermissionJS(true);
      }
    }
  }, [userData, fullAccessPermission, jobSchedulerGroup, userPermissions]);

  /***
   * Load groups depending on user's role
   */
  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      setLoadingGroups(true);
      const qb2 = RequestQueryBuilder.create();
      getEntityData(`${USERS_API}query/userGroupsCalendar`, qb2.query())
        .then((res) => {
          setLoadingGroups(false);
          setGroupsList(res.data.map((gu) => gu.group));
        })
        .catch((err) => {
          console.log('axios err=', err);
          setLoadingGroups(false);
        });
      generateFilterDates('Week');
    }
  }, [userData, setSelectedGroup, fullAccessPermission?.value_definition, isUserPermissionJS]);

  /***
   * Load sites
   */
  useEffect(() => {
    if (jobSchedulerGroup && jobSchedulerGroup.id) {
      setLoadingSites(true);
      const qb2 = RequestQueryBuilder.create();
      getEntityData(`${USERS_API}query/userSitesCalendar/${jobSchedulerGroup.id}`, qb2.query())
        .then((res) => {
          // console.log('📌 - file: GeneralView.js:396 - .then - res:', res);
          setLoadingSites(false);
          const sites = res.map((site, index) => ({
            id: site.id,
            name: site.name,
            color: site.color,
          }));
          const colors = res.map((site, index) => ({
            color: site.color,
          }));
          setColorList(colors);
          setSitesList(sites);
          setSelectedSitesList(sites);
        })
        .catch((err) => {
          console.log('axios err=', err);
          setLoadingSites(false);
        });
    }
  }, [jobSchedulerGroup]);
  useEffect(() => {
    const res = dataList;
    setLoadingData(false);
    if (viewListView === 'By Date') {
      res.sort((a, b) => (a.shift_date > b.shift_date ? 1 : -1));
    }
    if (viewListView === 'By Site') {
      res.sort((a, b) => (a.site.id > b.site.id ? 1 : -1));
    }
    if (size(res) > 0) {
      let array_data = [];
      let array_employee = [];
      let array_row = [];
      let ref_id = 0;
      let row_id = 0;
      let row_data = {};
      let subtotal_time = 0;
      let total_time = 0;
      if (viewListView === 'By Employee') {
        let employees = [];
        for (let y = 0; y < res.length; y++) {
          for (let i = 0; i < res[y].user_shifts.length; i++) {
            if (!employees.includes(res[y].user_shifts[i].user.id)) {
              employees.push(res[y].user_shifts[i].user.id);
            }
          }
          if (res[y].user_shifts.length === 0) {
            if (!employees.includes(0)) {
              employees.push(0);
            }
          }
        }
        employees.forEach((emp) => {
          row_data = {};
          array_data = [];
          total_time = 0;
          subtotal_time = 0;
          for (let x = 0; x < res.length; x++) {
            for (let y = 0; y < res[x].user_shifts.length; y++) {
              let users = res[x].user_shifts[y];
              if (emp === users.user.id) {
                row_data = users;
                array_data.push(res[x]);
                subtotal_time =
                  res[x].end_time !== null && res[x].start_time !== null
                    ? ShiftFullTimeDifferences(res[x])
                    : 0;
                total_time =
                  Math.floor((parseFloat(total_time) + parseFloat(subtotal_time)) * 100) / 100;
              }
            }
            if (res[x].user_shifts.length === 0) {
              if (emp === 0) {
                row_data = res[x];
                array_data.push(res[x]);
                subtotal_time =
                  res[x].end_time !== null && res[x].start_time !== null
                    ? ShiftFullTimeDifferences(res[x])
                    : 0;
                total_time =
                  Math.floor((parseFloat(total_time) + parseFloat(subtotal_time)) * 100) / 100;
              }
            }
          }
          array_data.sort((a, b) => (a.shift_date > b.shift_date ? -1 : 1));
          row_data.rows = array_data;
          row_data.total_time = total_time;
          array_employee.push(row_data);
        });
        array_employee = array_employee.map((i) => ({
          ...i,
          isEditableByUserRole: isUserPermissionJS,
        }));
        setDataListView(array_employee);
      } else if (viewListView === 'By Site') {
        res.filter(function (row, index) {
          if (index === 0) {
            if (viewListView === 'By Site') {
              ref_id = row.site?.id ? row.site.id : 0;
              row_data = row;
            } else {
              ref_id = row?.shift_date ? formatEspecial(row.shift_date, 'YYYY MM DD') : '';
              row_data = row;
            }
            subtotal_time =
              row.end_time !== null && row.start_time !== null ? ShiftFullTimeDifferences(row) : 0;
            total_time =
              Math.floor((parseFloat(total_time) + parseFloat(subtotal_time)) * 100) / 100;
            row_data.total_time = total_time;
            array_data.push(row_data);
            if (size(res) === 1) {
              row_data.rows = array_data;
              row_data.total_time = total_time;
              array_row.push(row_data);
            }
          } else {
            if (viewListView === 'By Site') {
              row_id = row.site?.id ? row.site.id : 0;
            } else {
              row_id = row?.shift_date ? formatEspecial(row.shift_date, 'YYYY MM DD') : '';
            }

            if (ref_id === row_id) {
              subtotal_time =
                row.end_time !== null && row.start_time !== null
                  ? ShiftFullTimeDifferences(row)
                  : 0;
              total_time =
                Math.floor((parseFloat(total_time) + parseFloat(subtotal_time)) * 100) / 100;
              row_data.total_time = total_time;
              array_data.push(row);
            } else {
              row_data.rows = array_data;
              row_data.total_time = total_time;
              row_data.rows.sort((a, b) => (a.shift_date > b.shift_date ? 1 : -1));
              array_row.push(row_data);
              row_data = {};
              total_time = 0;
              subtotal_time = 0;
              if (viewListView === 'By Site') {
                ref_id = row.site?.id ? row.site.id : 0;
                row_data = row;
              } else {
                ref_id = row?.shift_date ? formatEspecial(row.shift_date, 'YYYY MM DD') : '';
                row_data = row;
              }
              array_data = [];
              subtotal_time =
                row.end_time !== null && row.start_time !== null
                  ? ShiftFullTimeDifferences(row)
                  : 0;
              total_time =
                Math.floor((parseFloat(total_time) + parseFloat(subtotal_time)) * 100) / 100;
              row_data.total_time = total_time;
              array_data.push(row);
            }

            let final = parseFloat(size(res)) - 1;
            if (index === final) {
              row_data.rows = array_data;
              row_data.total_time = total_time;
              row_data.rows.sort((a, b) => (a.shift_date > b.shift_date ? 1 : -1));
              array_row.push(row_data);
              row_data = {};
              total_time = 0;
              subtotal_time = 0;
            }
          }
          if (viewListView === 'By Date') {
            array_row.sort((a, b) => (a.shift_date > b.shift_date ? 1 : -1));
          }
          return array_row;
        });
        array_row = array_row.map((i) => ({
          ...i,
          isEditableByUserRole: isUserPermissionJS,
        }));
        setDataListView(array_row);
      } else {
        console.log("minuto", res)
        setDataListView(res.map((item, i) => ({
          ...item,
          total: item.subtotal,
          rows: [item]
        })))
      }
    } else {
      setDataListView([]);
    }
  }, [dataList, viewListView, reloadSchedulerCalendar, isUserPermissionJS]);

  useEffect(() => {
    if (size(selectedSitesList) > 0) {
      setLoadingData(true);
      let keys = [
        'title',
        'shift_date',
        'end_time',
        'start_time',
        'user_shifts.employees.name',
        'jobsTitlesJobTitle.description',
        'shift_site.name',
      ];
      const fieldSearch = {};
      fieldSearch['site_id'] = { [CondOperator.IS_NULL]: 0 };
      const fieldsFiltering = [];
      if (debouncedSearchTerm) {
        const search = debouncedSearchTerm.replace(/\s/g, '').trim();
        if (search.length > 0) {
          keys.forEach((key) => {
            const fieldSearch = {};
            fieldSearch[key] = {
              [CondOperator.CONTAINS_LOW]: debouncedSearchTerm.toLowerCase().trim(),
            };
            fieldsFiltering.push(fieldSearch);
          });
        }
      }
      //Filters are added bellow, line 629
      let filters = [
        //{ field: 'status.value_definition', operator: CondOperator.EQUALS, value: '010' },
        /*{
          field: 'user_shifts.status.value_definition',
          operator: CondOperator.NOT_EQUALS,
          value: '020',
        }, //Draft shift
        {
          field: 'user_shifts.status.value_definition',
          operator: CondOperator.NOT_EQUALS,
          value: '040',
        }, //Template shift*/
      ];
      const number_sites = selectedSitesList.map((option) => option.id);
      if (size(number_sites) > 0) {
        if (number_sites.length !== sitesList.length) {
          filters = [
            ...filters,
            { field: 'shift_site.id', operator: CondOperator.IN, value: number_sites },
          ];
        } else {
          filters = [
            ...filters,
            //    { field: 'group.id', operator: CondOperator.EQUALS, value: jobSchedulerGroup.id },
          ];
        }
      }
      const fieldsFixedFiltering = [];
      let endPoint = SHIFTS_API;
      let sort = [{ field: 'shift_date', order: 'ASC' }];
      if (viewListView === 'By Site') {
        sort = [
          { field: 'shift_site.id', order: 'ASC' },
          { field: 'shift_date', order: 'ASC' },
          { field: 'start_time', order: 'ASC' },
        ];
      } else if (viewListView === 'By Employee') {
        sort = [
          { field: 'user_shifts.employees.id', order: 'DESC' },
          { field: 'shift_date', order: 'DESC' },
          { field: 'start_time', order: 'DESC' },
        ];
      } else {
        sort = [
          { field: 'shift_date', order: 'ASC' },
          { field: 'start_time', order: 'ASC' },
        ];
      }
      filters = [...filters, ...conditionDatesListView];
      filters &&
        filters.forEach((filter) => {
          const fieldSearch = {};
          fieldSearch[filter.field] = { [filter.operator]: filter.value };
          fieldsFixedFiltering.push(fieldSearch);
        });
      fieldsFixedFiltering.push({ $or: fieldsFiltering });
      fieldsFixedFiltering.push({
        $and: [
          {
            $or: [
              { 'employees_status.value_definition': '020' }, //Invited
              { 'employees_status.value_definition': '030' }, //Pending for approval
              { 'employees_status.value_definition': '040' }, //Approved
              { 'employees_status.value_definition': '090' }, //Cancelled
              { 'employees_status.value_definition': null }, //Published without employees
            ],
          },
        ],
      });
      const s = {
        $and: fieldsFixedFiltering,
      };
      const search = filters ? s : { $or: fieldsFiltering };
      const qb = RequestQueryBuilder.create()
        .sortBy(sort)
        .setLimit(limit)
        .setPage(page)
        .setJoin(currentJoins).
        search(search);
      getEntityData(endPoint, qb.query())
        .then((res) => {
          let filters = [];
          let fieldsFixedFiltering = [];
          filters = [
            { field: 'status.value_definition', operator: CondOperator.EQUALS, value: '020' },
            {
              field: 'creator_user_id',
              operator: CondOperator.EQUALS,
              value: userData.sub_cognito_id,
            },
          ];
          const number_sites = selectedSitesList.map((option) => option.id);
          if (size(number_sites) > 0) {
            if (number_sites.length !== sitesList.length) {
              filters = [
                ...filters,
                { field: 'shift_site.id', operator: CondOperator.IN, value: number_sites },
              ];
            } else {
              filters = [
                ...filters,
                //  { field: 'group.id', operator: CondOperator.EQUALS, value: jobSchedulerGroup.id },
              ];
            }
          }
          filters = [...filters, ...conditionDatesListView];
          filters &&
            filters.forEach((filter) => {
              const fieldSearch = {};
              fieldSearch[filter.field] = { [filter.operator]: filter.value };
              fieldsFixedFiltering.push(fieldSearch);
            });
          fieldsFixedFiltering.push({ $or: fieldsFiltering });
          let s = {};
          s = { $and: fieldsFixedFiltering };
          let search = {};
          search = filters ? s : { $or: fieldsFiltering };
          const qb = RequestQueryBuilder.create()
            .sortBy(sort)
            .setJoin(currentJoins)
            .setLimit(limit)
            .setPage(page)
            .search(search);
          getEntityData(endPoint, qb.query())
            .then((result) => {
              let data = [...(res.data || []), ...result.data];
              data.sort((a, b) => (a.user_shifts[0]?.user.id > b.user_shifts[0]?.user.id ? 1 : -1));
              data.page = result.page;
              data.pageCount = result.pageCount > res.pageCount ? result.pageCount : res.pageCount;
              data.total = result.total + res.total;
              setDataList(data);
              console.log("DATA", data);
              reloadSchedulerCalendar(false);
              calcPages(data.pageCount, data.total);
              if (data.total < 10) {
                setCurrentPage(`1-${data.length}`);
              }
            })
            .catch((err) => {
              setLoadingData(false);
              reloadSchedulerCalendar(false);
              console.log('axios err=', err);
            });
        })
        .catch((err) => {
          setLoadingData(false);
          reloadSchedulerCalendar(false);
          console.log('axios err=', err);
        });
    }
  }, [
    selectedSitesList,
    conditionDatesListView,
    viewListView,
    debouncedSearchTerm,
    userData,
    reloadScheduler,
    reloadSchedulerCalendar,
    currentJoins,
    sitesList,
    jobSchedulerGroup,
    page,
    currentPage
  ]);

  /***
   *
   */
  const getEmployee = (user) => {
    return (
      <AppDarkTooltip title={`${user.name}`}>
        <div style={{ cursor: 'pointer' }}>{calcAvatar(user, 28, 28)}</div>
      </AppDarkTooltip>
    );
  };

  /***
   * According to user shift info and user, get if user is on the way
   */
  const isOnTheWay = (shift, user) => {
    if (!shift || !user || !user.id) return false;
    // Find the user's shift
    const userShift = shift.user_shifts.find((us) => us.user.id === user.id);
    // Check for an 'on the way' event
    const onTheWay =
      userShift?.userShiftEvents.some((usE) => usE.type.value_definition === '080') || false;

    if (onTheWay === false) return;

    return (
      <AppDarkTooltip title={`${i18next.t('JobScheduler.shift_status.On_The_Way')}`}>
        <OnTheWay src={ic_on_the_way} alt="on_the_way" />
      </AppDarkTooltip>
    );
  };

  /***
   *
   */
  const status = (shift, user, simple) => {
    switch (shift.status?.value_definition) {
      case '020':
        return (
          <>
            <AppDarkTooltip title={`${user?.name} - ${t('JobScheduler.shift_status.Draft')}`}>
              <StatusContainer>
                <Status src={status_draft} />
                {`${simple ? t('JobScheduler.shift_status.Draft') : ''}`}
              </StatusContainer>
            </AppDarkTooltip>
            {isOnTheWay(shift, user)}
          </>
        );
      case '030':
        return (
          <>
            <AppDarkTooltip
              title={`${user.name} - ${t('JobScheduler.shift_status.Pending_Request')}`}
            >
              <StatusContainer>
                <Status src={ic_pending_approval} />
                {`${simple ? t('JobScheduler.shift_status.Pending_Request') : ''}`}
              </StatusContainer>
            </AppDarkTooltip>
            {isOnTheWay(shift, user)}
          </>
        );
      default:
        break;
    }

    if (user && user.id) {
      //Get shift
      const userShift = shift.user_shifts.filter((us) => us.user.id === user.id).map((us) => us)[0];
      //console.log('🦖🦖🦖 userShift: ', userShift);
      //Get status
      const statusValue = userShift.status?.value_definition;
      //console.log('🦖🦖🦖 statusValue: ', statusValue);
      //Get event running late (if applies)
      const runningLate = userShift.userShiftEvents.find(
        (usE) => usE.type.value_definition === '050',
      );
      //Get event no-show (if applies)

      const noShow = userShift.userShiftEvents.find((usE) => usE.type.value_definition === '070');
      //Get cancelation request (if applies)
      const cancelationRequest = userShift.user_shifts_requests.find(
        (usCR) => usCR.status.value_definition === '010',
      );
      //Invited
      if (statusValue === '020') {
        return (
          <>
            <AppDarkTooltip title={`${user.name} - ${t('JobScheduler.shift_status.Assigned')}`}>
              <StatusContainer>
                <Status src={ic_assigned} />
                {`${simple ? t('JobScheduler.shift_status.Assigned') : ''}`}
              </StatusContainer>
            </AppDarkTooltip>

            {isOnTheWay(shift, user)}
          </>
        );
        //Pending for approval
      } else if (statusValue === '030') {
        return (
          <>
            <AppDarkTooltip
              title={`${user.name} - ${t('JobScheduler.shift_status.Pending_Request')}`}
            >
              <StatusContainer>
                <Status src={ic_pending_approval} />
                {`${simple ? t('JobScheduler.shift_status.Pending_Request') : ''}`}
              </StatusContainer>
            </AppDarkTooltip>
            {isOnTheWay(shift, user)}
          </>
        );
        //Employee accpeted invitation
      } else if (statusValue === '040') {
        if (userShift.inProgress === true) {
          return (
            <>
              <AppDarkTooltip title={`${user.name} - ${t('JobScheduler.shift_status.Clocked_In')}`}>
                <StatusContainer>
                  <Status src={ic_clock_in} />
                  {`${simple ? t('JobScheduler.shift_status.Clocked_In') : ''}`}
                </StatusContainer>
              </AppDarkTooltip>
              {isOnTheWay(shift, user)}
            </>
          );
        } else if (
          userShift.inProgress === false &&
          userShift.sign_time === null &&
          userShift.signer_id === null
        ) {
          return (
            <>
              <AppDarkTooltip title={`${user.name} - ${t('JobScheduler.shift_status.Unsigned')}`}>
                <StatusContainer>
                  <Status src={ic_unsiged_hours} />
                  {`${simple ? t('JobScheduler.shift_status.Unsigned') : ''}`}
                </StatusContainer>
              </AppDarkTooltip>
              {isOnTheWay(shift, user)}
            </>
          );
        } else if (
          userShift.inProgress === false &&
          userShift.sign_time !== null &&
          userShift.signer_id !== null
        ) {
          return (
            <>
              <AppDarkTooltip title={`${user.name} - ${t('JobScheduler.shift_status.Completed')}`}>
                <StatusContainer>
                  <Status src={ic_completed_and_signed} />
                  {`${simple ? t('JobScheduler.shift_status.Completed') : ''}`}
                </StatusContainer>
              </AppDarkTooltip>
              {isOnTheWay(shift, user)}
            </>
          );
        } else if (cancelationRequest) {
          return (
            <>
              <AppDarkTooltip
                title={`${user.name} - ${t('JobScheduler.shift_status.Cancellation_Request')}`}
              >
                <StatusContainer>
                  <Status src={ic_cancellation_request} />
                  {`${simple ? t('JobScheduler.shift_status.Cancellation_Request') : ''}`}
                </StatusContainer>
              </AppDarkTooltip>
              {isOnTheWay(shift, user)}
            </>
          );
        } else if (noShow) {
          return (
            <>
              <AppDarkTooltip title={`${user.name} - ${t('JobScheduler.shift_status.No_Show')}`}>
                <StatusContainer>
                  <Status src={ic_no_show} />
                  {`${simple ? t('JobScheduler.shift_status.No_Show') : ''}`}
                </StatusContainer>
              </AppDarkTooltip>
              {isOnTheWay(shift, user)}
            </>
          );
        } else if (runningLate) {
          return (
            <>
              <AppDarkTooltip
                title={`${user.name} - ${t('JobScheduler.shift_status.Running_Late')}`}
              >
                <StatusContainer>
                  <Status src={ic_running_late} />
                  {`${simple ? t('JobScheduler.shift_status.Running_Late') : ''}`}
                </StatusContainer>
              </AppDarkTooltip>
              {isOnTheWay(shift, user)}
            </>
          );
        } else {
          return (
            <>
              <AppDarkTooltip title={`${user.name} - ${t('JobScheduler.shift_status.Covered')}`}>
                <StatusContainer>
                  <Status src={ic_claimed} />
                  {`${simple ? t('JobScheduler.shift_status.Covered') : ''}`}
                </StatusContainer>
              </AppDarkTooltip>
              {isOnTheWay(shift, user)}
            </>
          );
        }
      } else if (statusValue === '090') {
        return (
          <>
            <AppDarkTooltip title={`${user.name} - ${t('JobScheduler.shift_status.Canceled')}`}>
              <StatusContainer>
                <Status src={ic_canceled} />
                {`${simple ? t('JobScheduler.shift_status.Canceled') : ''}`}
              </StatusContainer>
            </AppDarkTooltip>
            {isOnTheWay(shift, user)}
          </>
        );
      } else {
        return;
      }
    }
    //Published
    if (shift.user_shifts.length === 0) {
      return (
        <StatusContainer>
          <Status src={ic_published} />
          {`${simple ? t('JobScheduler.shift_status.Published') : ''}`}
        </StatusContainer>
      );
    }
  };

  const shiftDateSimple = [
    {
      dataField: 'shift_date',
      text: `${t('table_columns.date')}`,
      sort: false,
      search: false,
      headerStyle: { width: '12%' },
      style: { minHeight: '54px', display: 'flex', alignItems: 'start' },
      formatter: (cellContent, row) => {
        if (row.shift_date) {
          return (
            /* <StyledLabel>{formatEspecial(row.shift_date, 'ddd D.MM')}</StyledLabel>*/
            <StyledLabel>
              {formatEspecial(row.shift_date, 'ddd') +
                ' ' +
                row.shift_date.substring(8, 10) +
                '.' +
                formatEspecial(row.shift_date, 'MM')}{' '}
            </StyledLabel>
          );
        } else {
          return (
            <StyledNestedCell
              style={{ backgroundColor: '#FFFFFF', display: 'flex', border: '6px solid #EEEEEE' }}
            >
              {t('status_types.No_Date')}
            </StyledNestedCell>
          );
        }
      },
    },
  ];
  const shiftDateGrid = [
    {
      dataField: 'shift_date',
      text: `${t('table_columns.date')}`,
      sort: false,
      search: false,
      headerStyle: { width: '12%' },
      style: { minHeight: '54px', padding: '0px' },
      formatter: (cellContent, row) => {
        if (size(row.rows) > 0) {
          return row.rows.map((orgSite, i) =>
            orgSite.shift_date ? (
              <div
                style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}
                key={i}
              >
                <StyledNestedCell style={{ display: 'flex', margin: '3px' }}>
                  {formatEspecial(orgSite.shift_date, 'ddd') +
                    ' ' +
                    orgSite.shift_date.substring(8, 10) +
                    '.' +
                    formatEspecial(orgSite.shift_date, 'MM')}
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    display: 'flex',
                    margin: '3px',
                    border: '6px solid #EEEEEE',
                  }}
                >
                  {t('status_types.No_Date')}
                </StyledNestedCell>{' '}
              </div>
            ),
          );
        } else {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: '#FFFFFF',
                display: 'block',
                paddingTop: '13px',
                margin: '3px',
                border: '6px solid #EEEEEE',
              }}
            >
              {' '}
              {t('status_types.No_Site')} {t('status_types.No_Date')}
            </StyledNestedCell>
          );
        }
      },
    },
  ];

  const getShiftStatusLabel = (code) => {
    switch (code) {
      case '070':
        return t('JobScheduler.shift_status.No_Show');
      default:
        return '';
    }
  };

  /*const compareArrays = (user_shifts1, user_shifts2) => {
    for (let i = 0; i < user_shifts1.length; i++) {
      for (let j = 0; j < user_shifts2.length; j++) {
        if (user_shifts1[i].user.id === user_shifts2[j].user.id) return true;
      }
    }

    return false;
  };*/

  /*const compareArraysJobTitles = (jobs_titles1, jobs_titles2) => {
    for (let i = 0; i < jobs_titles1.length; i++) {
      for (let j = 0; j < jobs_titles2.length; j++) {
        if (
          jobs_titles1[i]?.jobtitle?.value_definition_id ===
          jobs_titles2[j]?.jobtitle?.value_definition_id
        )
          return true;
      }
    }
    return false;
  };*/

  const calculateConflicts = (row) => {
    let show = true;
    //let push = false;
    let shiftConflicts = [];
    let data = row.rows;
    if (data && data.length > 0) {
      /*for (let i = 0; i < data.length; i++) {
        for (let j = 1; j < data.length; j++) {
          if (
            data[i].id !== data[j].id &&
            data[i].site &&
            data[i].site?.id === data[j].site?.id &&
            compareArraysJobTitles(data[i].jobs_titles, data[j].jobs_titles) &&
            compareArrays(data[i].user_shifts, data[j].user_shifts)
          ) {
            const starTime = moment(data[i].start_time).utc().format('YYYY-MM-DD HH:mm:ss');
            let endTime = moment(data[i].end_time).utc().format('YYYY-MM-DD HH:mm:ss');
            const starTimeShift = moment(data[j].start_time).utc().format('YYYY-MM-DD HH:mm:ss');
            let endTimeShift = moment(data[j].end_time)
              .add(60, 'minutes')
              .utc()
              .format('YYYY-MM-DD HH:mm:ss');

            if (endTime <= starTime) {
              endTime = moment(data[i].end_time).add(1, 'days').utc().format('YYYY-MM-DD HH:mm:ss');
              console.log('&&&& SUMMA DIA 1 !!!!!', data[i].title);
            }
            if (endTimeShift <= starTimeShift) {
              endTimeShift = moment(data[j].end_time)
                .add(1, 'days')
                .utc()
                .format('YYYY-MM-DD HH:mm:ss');
              console.log('&&&& SUMMA DIA F !!!!!', data[i].title, data[j].end_time, endTimeShift);
            }

            if (
              (endTimeShift >= starTime && starTimeShift <= starTime) ||
              (endTimeShift >= endTime && starTimeShift >= endTime && starTimeShift <= endTime) ||
              (endTimeShift <= endTime && starTimeShift >= starTime) ||
              (endTimeShift >= endTime && starTimeShift <= starTime)
            ) {
              if (!push) {
                push = true;
                shiftConflicts.push(data[i]);
                shiftConflicts.push(data[j]);
              }

              if (shiftConflicts.filter((s) => s.id === data[j].id).length === 0) {
                shiftConflicts.push(data[j]);
              }
            }
          }
        }
      }*/

      let result = data.map((site, i) =>
        shiftConflicts.length > 0 && shiftConflicts.filter((sh) => sh.id === site.id).length > 0 ? (
          show ? (
            <div
              style={{
                borderStyle: 'solid',
                borderColor: '#1179BF',
                borderRadius: 10,
                margin: 6,
                paddingBottom: 9,
              }}
            >
              {shiftConflicts.map((orgSite, i) => (
                <>
                  {orgSite.title ? (
                    <StyledNestedCell
                      style={{
                        display: 'flex',
                        marginRight: '3px',
                        marginLeft: '3px',
                        marginBottom: '3px',
                        paddingRight: '3px',
                        backgroundColor: PALETTE_COLOR.BORDER_LIST,
                      }}
                    >
                      {orgSite.title}
                    </StyledNestedCell>
                  ) : (
                    <div
                      style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}
                    >
                      (
                      <StyledNestedCell
                        style={{
                          backgroundColor: '#FFFFFF',
                          display: 'flex',
                          margin: '3px',
                          border: '6px solid #EEEEEE',
                        }}
                      >
                        {t('status_types.No_Title')}
                      </StyledNestedCell>
                      )
                    </div>
                  )}
                  {shiftConflicts.length !== i + 1 ? (
                    <Icon
                      src={chain}
                      style={{
                        width: 16,
                        height: 16,
                        display: 'flex',
                        marginTop: -10,
                        marginRight: 'auto',
                        marginBottom: -5,
                        marginLeft: 'auto',
                        zIndex: 1,
                        position: 'relative',
                      }}
                    />
                  ) : (
                    <div style={{ marginBottom: -10 }}></div>
                  )}
                </>
              ))}
              {(show = false)}
            </div>
          ) : (
            ''
          )
        ) : site.title ? (
          <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
            <StyledNestedCell style={{ display: 'flex', margin: '3px' }}>
              {site.title}
            </StyledNestedCell>
          </div>
        ) : (
          <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
            <StyledNestedCell
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                margin: '3px',
                border: '6px solid #EEEEEE',
              }}
            >
              {t('status_types.No_Title')}
            </StyledNestedCell>
          </div>
        ),
      );
      if (result) {
        return result;
      }
    }
    return [];
  };

  const shiftTitle = [
    {
      dataField: 'title',
      text: `${t('table_columns.title')}`,
      sort: false,
      search: true,
      headerStyle: { width: '20%' },
      style: { minHeight: '54px', padding: '0px' },
      formatter: (cellContent, row) => {
        if (size(row.rows) > 0) {
          return calculateConflicts(row);
        } else {
          return (
            <ShiftNestedCell
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                margin: '3px',
                border: '6px solid #EEEEEE',
              }}
              text={t('status_types.No_Title')}
            />
          );
        }
      },
    },
    {
      dataField: 'jobtitles',
      text: `${t('table_columns.job_titles')}`,
      sort: false,
      search: true,
      headerStyle: { width: '16%' },
      style: { minHeight: '54px', padding: '0px' },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((orgSite, i) =>
            orgSite.jobs_titles && orgSite.jobs_titles ? (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell style={{ display: 'flex', margin: '3px' }}>
                  {orgSite?.jobs_titles
                    ?.map((jobtitle) => jobtitle.jobtitle.description)
                    .join(', ')}
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    margin: '3px',
                    border: '6px solid #EEEEEE',
                  }}
                >
                  {' '}
                  {t('status_types.No_Job_Title')}
                </StyledNestedCell>
              </div>
            ),
          );
        } else {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: '#FFFFFF',
                display: 'block',
                paddingTop: '13px',
                margin: '3px',
                border: '6px solid #EEEEEE',
              }}
            >
              {' '}
              {t('no_time')}
            </StyledNestedCell>
          );
        }
      },
    },
  ];

  const shiftSiteSimple = [
    {
      dataField: 'site',
      text: `${t('table_columns.site')}`,
      sort: false,
      search: true,
      headerStyle: { width: '20%' },
      style: { minHeight: '54px', display: 'flex', alignItems: 'start' },
      formatter: (cellContent, row) => {
        if (row.site?.id) {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: row.organization?.color ? row.organization?.color : '#e7e9eb',
                display: 'block',
                paddingTop: '13px',
              }}
            >
              {row.site.name}
            </StyledNestedCell>
          );
        } else {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: '#FFFFFF',
                display: 'block',
                paddingTop: '13px',
                margin: '3px',
                border: '6px solid #EEEEEE',
              }}
            >
              {' '}
              {t('status_types.No_Site')}
            </StyledNestedCell>
          );
        }
      },
    },
  ];
  const shiftSiteGrid = [
    {
      dataField: 'site',
      text: `${t('table_columns.site')}`,
      sort: false,
      search: true,
      headerStyle: { width: '20%' },
      style: { minHeight: '54px', padding: '0px' },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((orgSite, i) =>
            orgSite.site?.id ? (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    border: `6px solid ${orgSite?.organization?.color}`,
                    backgroundColor:
                      orgSite.user_shifts?.length > 0
                        ? orgSite?.organization?.color
                          ? orgSite?.organization?.color
                          : '#e7e9eb'
                        : '',
                    display: 'flex',
                    margin: '3px',
                  }}
                >
                  {orgSite.site.name}
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    margin: '3px',
                    border: '6px solid #EEEEEE',
                  }}
                >
                  {' '}
                  {t('status_types.No_Site')}
                </StyledNestedCell>
              </div>
            ),
          );
        } else {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                margin: '3px',
                border: '6px solid #EEEEEE',
              }}
            >
              {' '}
              {t('status_types.No_Site')}
            </StyledNestedCell>
          );
        }
      },
    },
  ];
  const shiftEmployeeGrid = [
    {
      dataField: 'name',
      text: `${t('table_columns.employee')}`,
      search: true,
      headerStyle: { width: '20%' },
      style: { minHeight: '54px', padding: '0px', overflow: 'hidden' },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((shift, i) =>
            size(shift.user_shifts) > 0 ? (
              <div
                style={{
                  borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                <StyledNestedCell
                  style={{
                    backgroundColor: 'transparent',
                    display: 'flex',
                    margin: '3px',
                    alignItems: 'start',
                    flexWrap: 'wrap',
                  }}
                >
                  {size(shift.user_shifts) === 1 ? (
                    <>
                      {' '}
                      {shift.user_shifts.map((users, i) => (
                        <div style={{ display: 'flex' }}>
                          {calcAvatar(users.user)}
                          <StyledIconLabel>{users.user.name}</StyledIconLabel>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {shift.user_shifts.map((users, i) => (
                          <>{getEmployee(users.user)}</>
                        ))}
                      </div>
                      <StyledIconLabel style={{ fontSize: '12px' }}>
                        {size(shift.user_shifts)} {t('form_labels.employees')}
                      </StyledIconLabel>
                    </>
                  )}
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    margin: '3px',
                    border: '6px solid #EEEEEE',
                    alignItems: 'start',
                  }}
                >
                  {t('status_types.No_Users')}
                </StyledNestedCell>
              </div>
            ),
          );
        } else {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                margin: '3px',
                border: '6px solid #EEEEEE',
                alignItems: 'start',
              }}
            >
              {' '}
              {t('status_types.No_Users')}
            </StyledNestedCell>
          );
        }
      },
    },
  ];

  const shiftEmployeeSimple = [
    {
      dataField: 'name',
      text: `${t('table_columns.employee')}`,
      search: true,
      headerStyle: { width: '20%' },
      style: { minHeight: '54px' },
      formatter: (cellContent, row) => {
        if (row.user) {
          return (
            <div>
              <StyledLabel>
                {calcAvatar(row.user)}
                <StyledIconLabel>{row.user.name}</StyledIconLabel>
              </StyledLabel>
            </div>
          );
        } else {
          return (
            <StyledNestedCell
              style={{ backgroundColor: '#FFFFFF', display: 'flex', border: '6px solid #EEEEEE' }}
            >
              {t('status_types.No_Employee')}
            </StyledNestedCell>
          );
        }
      },
    },
  ];
  const listViewColumns = [
    {
      dataField: 'status_id.description',
      text: `${t('table_columns.status')}`,
      sort: true,
      search: true,
      headerStyle: { width: '24%' },
      style: { minHeight: '54px', padding: '0px' },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((shift, i) =>
            shift.status ? (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '3px',
                  }}
                >
                  {viewListBy.current === 'By Employee'
                    ? status(shift, row?.user, true)
                    : shift?.user_shifts &&
                      shift?.user_shifts.length !== 0 &&
                      shift?.user_shifts.length > 1
                      ? shift?.user_shifts.map((us) => {
                        return status(shift, us?.user, false);
                      })
                      : status(shift, shift?.user_shifts[0]?.user, true)}
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    margin: '3px',
                    border: '6px solid #EEEEEE',
                  }}
                >
                  {' '}
                  {t('status_types.No_Status')}
                </StyledNestedCell>
              </div>
            ),
          );
        }
      },
      advanceFilter: statuses && [
        ...statuses
          .filter((s) => s.description !== 'Blocked')
          .map((s) => ({
            label: getShiftStatusLabel(s.value_definition),
            value: s.description,
          }))
          .filter((s) => s.label),
        {
          label: t('JobScheduler.shift_status.Clocked_In'),
          value: 'CLOCKED_IN',
        },
        {
          label: t('JobScheduler.shift_status.Covered'),
          value: 'COVERED',
        },
        {
          label: t('JobScheduler.shift_status.Running_Late'),
          value: 'RUNNING_LATE',
        },
        {
          label: t('JobScheduler.shift_status.Unsigned'),
          value: 'UNSIGNED',
        },
        {
          label: t('JobScheduler.shift_status.Completed'),
          value: 'COMPLETED',
        },
      ],
    },
    {
      dataField: 'time',
      text: `${t('table_columns.time')}`,
      sort: false,
      search: false,
      headerStyle: { width: '10%' },
      style: { minHeight: '54px', padding: '0px' },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((orgSite, i) =>
            orgSite.start_time && orgSite.end_time ? (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell style={{ display: 'flex', margin: '3px' }}>
                  {ShiftTimes(orgSite)}
                  {/* {formatEspecial(orgSite.start_time, 'h a') +
                    ' - ' +
                    formatEspecial(orgSite.end_time, 'h a')} */}
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    margin: '3px',
                    border: '6px solid #EEEEEE',
                  }}
                >
                  {' '}
                  {t('no_time')}
                </StyledNestedCell>
              </div>
            ),
          );
        } else {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: '#FFFFFF',
                display: 'block',
                paddingTop: '13px',
                margin: '3px',
                border: '6px solid #EEEEEE',
              }}
            >
              {' '}
              {t('no_time')}
            </StyledNestedCell>
          );
        }
      },
    },
    {
      dataField: 'subtotal',
      text: `${t('table_columns.subtotal')}`,
      sort: false,
      search: false,
      headerStyle: { width: '10%' },
      style: { minHeight: '54px', padding: '0px' },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((orgSite, i) =>
            orgSite.start_time && orgSite.end_time ? (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell style={{ display: 'flex', margin: '3px' }}>
                  {ShiftFullTimeDifferences(orgSite) + ' h'}
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    margin: '3px',
                    border: '6px solid #EEEEEE',
                  }}
                >
                  {' '}
                  {t('no_time')}
                </StyledNestedCell>
              </div>
            ),
          );
        } else {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                margin: '3px',
                border: '6px solid #EEEEEE',
              }}
            >
              {' '}
              {t('no_time')}
            </StyledNestedCell>
          );
        }
      },
    },
    {
      dataField: 'total',
      text: `${t('table_columns.total')}`,
      sort: false,
      search: false,
      headerStyle: { width: '8%' },
      style: { minHeight: '54px' },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((orgSite, i) =>
            orgSite.start_time && orgSite.end_time ? (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell style={{ display: 'flex', margin: '3px' }}>
                  {ShiftFullTimeDifferences(orgSite) + ' h'}
                </StyledNestedCell>
              </div>
            ) : (
              <div style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}>
                <StyledNestedCell
                  style={{
                    backgroundColor: '#FFFFFF',
                    display: 'flex',
                    margin: '3px',
                    border: '6px solid #EEEEEE',
                  }}
                >
                  {' '}
                  {t('no_time')}
                </StyledNestedCell>
              </div>
            ),
          );
        } else {
          return (
            <StyledNestedCell
              style={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                margin: '3px',
                border: '6px solid #EEEEEE',
              }}
            >
              {' '}
              {t('no_time')}
            </StyledNestedCell>
          );
        }
      },
    },
    {
      dataField: 'actions',
      text: `${t('table_columns.details')}`,
      sort: false,
      search: false,
      headerStyle: { width: '9%' },
      formatter: (cellContent, row) => {
        if (row.rows) {
          return row.rows.map((item, i) => {
            return !isLoading ? (
              <div
                style={{ borderTop: i !== 0 ? '1px solid rgba(0, 0, 0, 0.125)' : 'unset' }}
                onClick={() => {
                  setUser(item?.user_shifts[0].user);
                  setShift(item);
                  consultShift(item?.id, item?.user_shifts[0].user);
                }}
              >
                <StyledNestedCell
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '3px',
                  }}
                >
                  <ViewIcon src={ic_eye} alt="logo"></ViewIcon>
                </StyledNestedCell>
              </div>
            ) : (
              <div></div>
            );
          });
        }
      },
    },
  ];

  const defaultColumns = [
    ...shiftDateSimple,
    ...shiftTitle,
    ...shiftSiteGrid,
    ...shiftEmployeeGrid,
    ...listViewColumns,
  ];

  const [columnList, setColumnList] = useState(defaultColumns);

  /***
   *
   */
  const generateFilterDates = (view) => {
    let fecha = new Date();
    let filters = [];
    if (view === 'Day') {
      setLabelDatesListView(formatEspecial(fecha, 'MMM D'));
      let dates = [];
      dates.push(formatEspecial(fecha, 'YYYY-MM-DD') + ' 00:00');
      dates.push(formatEspecial(fecha, 'YYYY-MM-DD') + ' 23:59');
      filters = [{ field: 'shift_date', operator: CondOperator.BETWEEN, value: dates }];
      setConditionDatesListView(filters);
      setDayList(fecha);
      setYearList(formatEspecial(fecha, 'YYYY'));
      setWeekList(currentWeekNumber(fecha));
      setFilterDates({
        start: formatEspecial(fecha, 'YYYY-MM-DD') + ' 00:00',
        end: formatEspecial(fecha, 'YYYY-MM-DD') + ' 23:59',
      });
    } else if (view === 'Week') {
      /*let week = currentWeekNumber(fecha);
      let primerdia = new Date(fecha.getFullYear(), 0, 1);
      let correccion = 6 - primerdia.getDay();
      let primer = new Date(fecha.getFullYear(), 0, (week - 1) * 7 + 3 + correccion);
      let ultimo = new Date(fecha.getFullYear(), 0, (week - 1) * 7 + 9 + correccion);
      primer = primer.setDate(primer.getDate() - 1);
      ultimo = ultimo.setDate(ultimo.getDate() - 1);
      let primerMes = formatEspecial(primer, 'MMMM');
      let segundoMes = formatEspecial(ultimo, 'MMMM');
      if (primerMes === segundoMes) {
        setLabelDatesListView(
          formatEspecial(primer, 'MMM D') + ' - ' + formatEspecial(ultimo, 'D'),
        );
      } else {
        setLabelDatesListView(
          formatEspecial(primer, 'MMM D') + ' - ' + formatEspecial(ultimo, 'MMM D'),
        );
      }
      
      console.log(formatEspecial(primer, 'YYYY-MM-DD'));
      console.log(formatEspecial(ultimo, 'YYYY-MM-DD'));
      dates.push(formatEspecial(primer, 'YYYY-MM-DD'));
      dates.push(formatEspecial(ultimo, 'YYYY-MM-DD'));
      filters = [{ field: 'shift_date', operator: CondOperator.BETWEEN, value: dates }];
      setConditionDatesListView(filters);
      setFilterDates({
        start: formatEspecial(primer, 'YYYY-MM-DD') + ' 00:00',
        end: formatEspecial(ultimo, 'YYYY-MM-DD') + ' 23:59',
      });*/

      const prevStart = moment.utc(fecha).add(-7, 'day');
      const prevEnd = moment.utc(fecha).add(-1, 'day');
      setLabelDatesListView(
        formatEspecial(prevStart, 'MMM D') + ' - ' + formatEspecial(prevEnd, 'MMM D'),
      );
      let dates = [];
      dates.push(formatEspecial(prevStart, 'YYYY-MM-DD'));
      dates.push(formatEspecial(prevEnd, 'YYYY-MM-DD'));
      filters = [{ field: 'shift_date', operator: CondOperator.BETWEEN, value: dates }];
      setConditionDatesListView(filters);
      setFilterDates({
        start: formatEspecial(prevStart, 'YYYY-MM-DD') + ' 00:00',
        end: formatEspecial(prevEnd, 'YYYY-MM-DD') + ' 23:59',
      });
    } else {
      setLabelDatesListView(formatEspecial(fecha, 'MMM'));
    }
  };

  /***
   * **********************************************************
   */
  const continueDate = (mode) => {
    let filters = [];
    if (mode !== 'Today') {
      if (filterDatesListView === 'Day') {
        if (mode === 'Add') {
          dayList.setDate(dayList.getDate() + 1);
        } else {
          dayList.setDate(dayList.getDate() - 1);
        }
        setLabelDatesListView(formatEspecial(dayList, 'MMM D'));
        setDayList(dayList);
        setYearList(formatEspecial(dayList, 'YYYY'));
        setWeekList(currentWeekNumber(dayList));
        let dates = [];
        dates.push(formatEspecial(dayList, 'YYYY-MM-DD') + ' 00:00');
        dates.push(formatEspecial(dayList, 'YYYY-MM-DD') + ' 23:59');
        filters = [{ field: 'shift_date', operator: CondOperator.BETWEEN, value: dates }];
        setConditionDatesListView(filters);
        setFilterDates({
          start: formatEspecial(dayList, 'YYYY-MM-DD') + ' 00:00',
          end: formatEspecial(dayList, 'YYYY-MM-DD') + ' 23:59',
        });
      } else if (filterDatesListView === 'Week') {
        let week = weekList;
        let year = yearList;
        if (mode === 'Add') {
          week = parseFloat(weekList) + 1;
        } else {
          week = parseFloat(weekList) - 1;
        }
        if (week === 0) {
          week = 52;
          year = parseFloat(year) - 1;
        }
        if (parseFloat(week) >= 53) {
          week = 0;
          year = parseFloat(year) + 1;
        }
        let primerdia = new Date(year, 0, 1);
        let correccion = 6 - primerdia.getDay();
        let primer = new Date(year, 0, (week - 1) * 7 + 3 + correccion);
        let ultimo = new Date(year, 0, (week - 1) * 7 + 9 + correccion);
        primer = primer.setDate(primer.getDate() - 1);
        ultimo = ultimo.setDate(ultimo.getDate() - 1);
        let primerMes = formatEspecial(primer, 'MMMM');
        let segundoMes = formatEspecial(ultimo, 'MMMM');
        if (primerMes === segundoMes) {
          setLabelDatesListView(
            formatEspecial(primer, 'MMM D') + ' - ' + formatEspecial(ultimo, 'D'),
          );
        } else {
          setLabelDatesListView(
            formatEspecial(primer, 'MMM D') + ' - ' + formatEspecial(ultimo, 'MMM D'),
          );
        }
        setWeekList(week);
        if (mode === 'Add') {
          if (year !== formatEspecial(ultimo, 'YYYY')) {
            setYearList(formatEspecial(ultimo, 'YYYY'));
          }
        } else {
          if (year !== formatEspecial(primer, 'YYYY')) {
            setYearList(formatEspecial(primer, 'YYYY'));
          }
        }
        let dates = [];
        dates.push(formatEspecial(primer, 'YYYY-MM-DD'));
        dates.push(formatEspecial(ultimo, 'YYYY-MM-DD'));
        filters = [{ field: 'shift_date', operator: CondOperator.BETWEEN, value: dates }];
        setConditionDatesListView(filters);
        setFilterDates({
          start: formatEspecial(primer, 'YYYY-MM-DD') + ' 00:00',
          end: formatEspecial(ultimo, 'YYYY-MM-DD') + ' 23:59',
        });
      }
    } else {
      let fecha = new Date();
      setLabelDatesListView(formatEspecial(fecha, 'MMM D'));
      let dates = [];
      dates.push(formatEspecial(fecha, 'YYYY-MM-DD'));
      dates.push(formatEspecial(fecha, 'YYYY-MM-DD'));
      filters = [{ field: 'shift_date', operator: CondOperator.BETWEEN, value: dates }];
      setConditionDatesListView(filters);
      setDayList(fecha);
      setYearList(formatEspecial(fecha, 'YYYY'));
      setWeekList(currentWeekNumber(fecha));
      setFilterDates({
        start: formatEspecial(fecha, 'YYYY-MM-DD') + ' 00:00',
        end: formatEspecial(fecha, 'YYYY-MM-DD') + ' 23:59',
      });
    }
  };

  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay], // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }

  return (
    <>
      <Formik
        initialValues={{
          group: { label: jobSchedulerGroup.name, value: jobSchedulerGroup },
          sites: [],
        }}
        enableReinitialize={true}
      >
        {({ handleSubmit, handleChange, values, setFieldValue }) => (
          <div>
            <EncList
              title={t('JobScheduler.job_scheduler')}
              indexActive={getActiveTabIndex(viewType)}
              icon={Calender}
              filters={[
                {
                  filter: loadingGroups ? (
                    <AppComponentLoading />
                  ) : (
                    <AppDropDownSingleSelect
                      isSearchable={true}
                      allowSelectAll={false}
                      height="15rem"
                      width={270}
                      name="group"
                      onChange={(selected) => {
                        setFieldValue('group', selected);
                        setSelectedGroup(selected?.value);
                      }}
                      value={values.group}
                      options={groupsList.map((option) => ({ label: option.name, value: option }))}
                      targetComponent={
                        <StyleFilter style={{ cursor: 'pointer' }}>
                          <FilterIcon src={Ellipse}></FilterIcon>
                          <TittleFilter>
                            {values.group.label
                              ? values.group.label
                              : size(groupsList) > 0
                                ? groupsList[0].name
                                : `${t('not_found')}`}
                          </TittleFilter>
                          <FilterIcon src={Down}></FilterIcon>
                        </StyleFilter>
                      }
                      defaultValue={{}}
                    />
                  ),
                },
                {
                  filter: loadingSites ? (
                    <AppComponentLoading />
                  ) : (
                    <AppDropDownCheckBoxSelect
                      showSelection={false}
                      options={sitesList.map((option) => ({
                        label: option.name,
                        value: option.name,
                        valueObj: option,
                      }))}
                      isSearchable={true}
                      allowSelectAll={true}
                      height="15rem"
                      typeSelectAll="allEmpy"
                      width={390}
                      onChange={(newSelectedOptions, event, selected) => {
                        setFieldValue('sites', selected);
                        let sites = [];

                        if (size(selected) > 0) {
                          sites = selected.map((org, index) => ({
                            id: org.valueObj?.id,
                            name: org.label,
                            color: org.valueObj?.color,
                          }));
                        } else {
                          sites = sitesList;
                        }
                        setSelectedSitesList(sites);
                      }}
                      selectAllControlOption={{
                        label: `${t('form_labels.all_sites')}`,
                        value: '*',
                        filter: 'site',
                        isSelected: true,
                        typeSelectAll: 'allEmpy',
                        coloredCircles: true,
                      }}
                      defaultValue={values.sites}
                      targetComponent={
                        <StyleFilter>
                          {size(values.sites) > 0 ? (
                            <StyleColor>
                              {values.sites
                                .filter((c, ix) => ix < 3)
                                .map((c, index) => (
                                  <>
                                    {!c.value.includes('*') && (
                                      <StyleInternalColor color={c.valueObj.color} />
                                    )}
                                  </>
                                ))}
                            </StyleColor>
                          ) : (
                            <StyleColor>
                              {colorList
                                .filter((c, ix) => ix < 3)
                                .map((c, index) => (
                                  <>
                                    <StyleInternalColor color={c.color} />
                                  </>
                                ))}
                            </StyleColor>
                          )}

                          <TittleFilter>
                            {size(values.sites) > 0
                              ? size(values.sites) === 1
                                ? size(values.sites) + ` ${t('form_labels.site')}`
                                : sitesList.length > size(values.sites)
                                  ? size(values.sites) + ` ${t('form_labels.sites')}`
                                  : `${t('form_labels.all_sites')}`
                              : `${t('form_labels.all_sites')}`}
                          </TittleFilter>
                          <FilterIcon src={Down}></FilterIcon>
                        </StyleFilter>
                      }
                    />
                  ),
                },
              ]}
              options={[
                {
                  label: `${t('JobScheduler.header_buttons.calendar')}`,
                  onClick: () => {
                    setViewType('calendar');
                    handleChangeTypeURL('calendar');
                  },
                },
                {
                  label: `${t('JobScheduler.header_buttons.list_view')}`,
                  onClick: () => {
                    setViewType('list-view');
                    handleChangeTypeURL('list-view');
                  },
                },
                {
                  label: `${t('JobScheduler.header_buttons.activity')}`,
                  onClick: () => {
                    setViewType('activity');
                    handleChangeTypeURL('activity');
                  },
                },
              ]}
            ></EncList>

            {viewType === 'calendar' ? (
              <Calendar
                sites={selectedSitesList}
                allSitesSelected={selectedSitesList.length === sitesList.length}
                group={jobSchedulerGroup}
                handleClickOpenCreateSnglShiftModal={handleClickOpenCreateSnglShiftModal}
                isUserPermissionJS={isUserPermissionJS}
              />
            ) : viewType === 'list-view' ? (
              <AppTable
                key="list-view"
                columns={columnList}
                footerAdditional={[
                  {
                    footer: (
                      <BottomWrapper data-testid={`_table_pagination_bottom`}>
                        <Row>
                          <Col
                            xl={2}
                            lg={2}
                            style={{ display: 'flex' }}
                            data-testid={`_table_pagination`}
                          >
                            <PageLabelWrapper>{t('AppTable.page')}</PageLabelWrapper>
                            <PageLabelWrapper style={{ fontWeight: 'bold' }}>
                              {dataList?.page}
                            </PageLabelWrapper>
                            <PageLabelWrapper>{t('AppTable.of')}</PageLabelWrapper>
                            <PageLabelWrapper style={{ fontWeight: 'bold' }}>
                              {dataList?.pageCount}
                            </PageLabelWrapper>
                          </Col>
                          <Col
                            xl={7}
                            lg={7}
                            style={{ alignItems: 'center', textAlign: 'center' }}
                            data-testid={`_table_pagination_controls`}
                          >
                            <IconButton
                              aria-label={t('AppTable.aria_job_scheduler')}
                              disabled={page === 1}
                              onClick={(e) => {
                                setCurrentPage(pages[page - 2]);
                                setPage(page - 1);
                              }}
                            >
                              <ArrowWrapper
                                style={{ height: 10, width: 10, marginTop: 5 }}
                                src={page !== 1 ? ic_arrow_left_enabled : ic_arrow_left}
                                alt="IcArrowLeft"
                              ></ArrowWrapper>
                            </IconButton>
                            <IconButton
                              aria-label={t('AppTable.aria_job_scheduler')}
                              style={{ marginLeft: 30 }}
                              disabled={page === dataList.pageCount}
                              onClick={(e) => {
                                setCurrentPage(pages[page]);
                                setPage(page + 1);
                              }}
                            >
                              <ArrowWrapper
                                style={{ height: 10, width: 10, marginTop: 5 }}
                                src={page === dataList.pageCount ? ic_arrow_right : ic_arrow_right_enabled}
                                alt="IcArrowLeft"
                              ></ArrowWrapper>
                            </IconButton>
                          </Col>
                          <Col
                            xl={3}
                            lg={3}
                            style={{ display: 'flex' }}
                            data-testid={`_table_pagination_results_per_page`}
                          >
                            <Menu
                              menuButton={
                                <ResultsWrapper>
                                  {t('AppTable.results_page')}
                                  <div
                                    style={{
                                      fontWeight: 'bold',
                                      marginLeft: 5,
                                      position: 'absolute',
                                      right: '50px',
                                    }}
                                  >
                                    {limit}
                                  </div>
                                  <ArrowWrapper
                                    src={ic_arrow_down}
                                    style={{ marginLeft: 5, position: 'absolute', right: '25px' }}
                                    alt="IcArrowWrapper"
                                  ></ArrowWrapper>
                                </ResultsWrapper>
                              }
                              transition
                            >
                              {DEFAULT_PAGINATION_RESULTS_OPTIONS.map((v, i) => (
                                <MenuItem
                                  key={i}
                                  onClick={(e) => {
                                    setPage(1);
                                    setLimit(v);
                                    setCurrentPage(`1-${v}`);
                                  }}
                                >
                                  {v}
                                </MenuItem>
                              ))}
                            </Menu>
                          </Col>
                        </Row>
                      </BottomWrapper>)
                  }]
                }
                filtersAdditional={[
                  {
                    filter: (
                      <Menu
                        menuButton={
                          <AdvanceFilterButtonWrapper
                            style={{ marginRight: 10, width: 'max-content', marginBottom: '15px' }}
                          >
                            {t(
                              `JobScheduler.menu.${viewListView.toLowerCase().replace(/\s/g, '_')}`,
                            )}
                            <IcArrow
                              style={{ marginLeft: 5, cursor: 'pointer' }}
                              status={'enabled'}
                              direction={'down'}
                            />
                          </AdvanceFilterButtonWrapper>
                        }
                      >
                        <MenuItem
                          onClick={(e) => {
                            let newColumns = [
                              ...shiftDateSimple,
                              ...shiftTitle,
                              ...shiftSiteGrid,
                              ...shiftEmployeeGrid,
                              ...listViewColumns,
                            ];
                            setPages(['1-10']);
                            setPage(['1-10']);
                            setCurrentPage('1-10');
                            setPage(1);
                            setLimit(10);
                            setColumnList(newColumns);
                            setViewListView('By Date');
                            viewListBy.current = 'By Date';
                          }}
                        >
                          {t('JobScheduler.menu.by_date')}
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            let newColumns = [
                              ...shiftEmployeeSimple,
                              ...shiftTitle,
                              ...shiftSiteGrid,
                              ...shiftDateGrid,
                              ...listViewColumns,
                            ];
                            setPages(['1-10']);
                            setPage(['1-10']);
                            setCurrentPage('1-10');
                            setPage(1);
                            setLimit(10);
                            setColumnList(newColumns);
                            setViewListView('By Employee');
                            viewListBy.current = 'By Employee';
                          }}
                        >
                          {t('JobScheduler.menu.by_employee')}
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            let newColumns = [
                              ...shiftSiteSimple,
                              ...shiftTitle,
                              ...shiftEmployeeGrid,
                              ...shiftDateGrid,
                              ...listViewColumns,
                            ];
                            setPages(['1-10']);
                            setPage(['1-10']);
                            setCurrentPage('1-10');
                            setPage(1);
                            setLimit(10);
                            setColumnList(newColumns);
                            setViewListView('By Site');
                            viewListBy.current = 'By Site';
                          }}
                        >
                          {t('JobScheduler.menu.by_site')}
                        </MenuItem>
                      </Menu>
                    ),
                  },
                  {
                    filter: (
                      <Menu
                        menuButton={
                          <AdvanceFilterButtonWrapper
                            style={{ marginRight: 10, width: 'max-content', marginBottom: '15px' }}
                          >
                            {t(`JobScheduler.menu.${filterDatesListView.toLowerCase()}`)}
                            <IcArrow
                              style={{ marginLeft: 5, cursor: 'pointer' }}
                              status={'enabled'}
                              direction={'down'}
                            />
                          </AdvanceFilterButtonWrapper>
                        }
                      >
                        <MenuItem
                          onClick={(e) => {
                            setPages(['1-10']);
                            setPage(['1-10']);
                            setCurrentPage('1-10');
                            setPage(1);
                            setLimit(10);
                            generateFilterDates('Day');
                            setFilterDatesListView('Day');
                          }}
                        >
                          {t('JobScheduler.menu.day')}
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            setPages(['1-10']);
                            setPage(['1-10']);
                            setCurrentPage('1-10');
                            setPage(1);
                            setLimit(10);
                            generateFilterDates('Week');
                            setFilterDatesListView('Week');
                          }}
                        >
                          {t('JobScheduler.menu.week')}
                        </MenuItem>
                      </Menu>
                    ),
                  },
                  {
                    filter: (
                      <PaginationTopButton>
                        <IconButton
                          aria-label={t('form_labels.job_scheduler')}
                          style={{ marginLeft: 2, marginRight: 2 }}
                          onClick={(e) => {
                            setPages(['1-10']);
                            setPage(['1-10']);
                            setCurrentPage('1-10');
                            setPage(1);
                            setLimit(10);
                            continueDate('Quit');
                          }}
                        >
                          <IcArrow
                            style={{ height: 10, width: 5 }}
                            direction="left"
                            status={'enabled'}
                          />
                        </IconButton>
                        <div style={{ color: '#1179BF', marginRight: 5 }}>{labelDatesListView}</div>
                        <IconButton
                          aria-label={t('form_labels.job_scheduler')}
                          onClick={(e) => {
                            setPages(['1-10']);
                            setPage(['1-10']);
                            setCurrentPage('1-10');
                            setPage(1);
                            setLimit(10);
                            continueDate('Add');
                          }}
                        >
                          <IcArrow
                            style={{ height: 10, width: 5 }}
                            direction={'right'}
                            status={'enabled'}
                          />
                        </IconButton>
                      </PaginationTopButton>
                    ),
                  },
                  {
                    filter: (
                      <AppButton
                        size="lg"
                        bgcolor={PALETTE_COLOR.MAIN_WHITE}
                        bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                        color={PALETTE_COLOR.MAIN_BLUE}
                        bordercolor={PALETTE_COLOR.TOP_GRAY}
                        borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                        style={{ marginRight: '-5px', marginLeft: '10px' }}
                        onClick={(e) => {
                          setPages(['1-10']);
                          setPage(['1-10']);
                          setCurrentPage('1-10');
                          setPage(1);
                          setLimit(10);
                          setFilterDatesListView('Day');
                          generateFilterDates('Day');
                          continueDate('Today');
                        }}
                      >
                        {t('buttons.btn_today')}
                      </AppButton>
                    ),
                  },
                  {
                    filter: (
                      <PaginationTopButton data-testid={`_table_pagination_top`}>
                        <div>{currentPage}</div>
                        <Menu
                          menuButton={
                            <ArrowWrapper
                              src={ic_arrow_down}
                              style={{ marginLeft: 5, cursor: 'pointer' }}
                              alt="IcArrowWrapper"
                            ></ArrowWrapper>
                          }
                          transition
                        >
                          <Scrollbars autoHeight>
                            {pages.map((v, i) => (
                              <MenuItem
                                key={i}
                                onClick={(e) => {
                                  setPage(i + 1);
                                  setLimit(limit);
                                  setCurrentPage(v);
                                }}
                              >
                                {v}
                              </MenuItem>
                            ))}
                          </Scrollbars>
                        </Menu>

                        <PaginationTopButtonSeparator />
                        <div style={{ color: '#878E97', marginRight: 5 }}>{t('AppTable.of')}</div>
                        {dataList?.total}
                        <IconButton
                          aria-label={t('AppTable.aria_job_scheduler')}
                          style={{ marginLeft: 5 }}
                          disabled={page === 1}
                          onClick={(e) => {
                            setCurrentPage(pages[page - 2]);
                            setPage(page - 1);
                          }}
                        >
                          <ArrowWrapper
                            style={{ height: 10, width: 5 }}
                            src={page !== 1 ? ic_arrow_left_enabled : ic_arrow_left}
                            alt="IcArrowLeft"
                          ></ArrowWrapper>
                        </IconButton>
                        <IconButton
                          aria-label={t('AppTable.aria_job_scheduler')}
                          disabled={page === dataList.pageCount}
                          onClick={(e) => {
                            setCurrentPage(pages[page]);
                            setPage(page + 1);
                          }}
                        >
                          <ArrowWrapper
                            style={{ height: 10, width: 5 }}
                            src={page === dataList.length ? ic_arrow_right : ic_arrow_right_enabled}
                            alt="IcArrowLeft"
                          ></ArrowWrapper>
                        </IconButton>
                      </PaginationTopButton>)
                  }
                ]}
                disablePagination={true}
                disableTopPagination={true}
                withAdvanceFilter={true}
                customSetSelectedFilters={(selected) => {
                  if (selected.length > 0) {
                    setCurrentJoins([
                      ...DEFAULT_JOINS,
                      ...selected.map((s) => ({ field: s.value })),
                    ]);
                  } else {
                    setCurrentJoins(DEFAULT_JOINS);
                  }
                }}
                csvName={t('csv_names.export_list_view_csv_name')}
                isSearchable={false}
                search_alter={
                  <InputForm
                    style={{ marginLeft: 15, marginRight: 15 }}
                    height={'40px'}
                    widthspan={'35%'}
                    widthinput={'100%'}
                    placeholder={t('placeholder_search')}
                    icon={ic_search}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                }
                joins={[
                  { field: 'organization' },
                  { field: 'status' },
                  { field: 'site' },
                  { field: 'user_shifts' },
                  { field: 'user_shifts.user' },
                ]}
                data={dataListView}
                loading={loadingData}
                trailingBtn={
                  <div
                    style={{
                      display: 'flex',
                      height: '40px',
                      position: 'absolute',
                      right: 0,
                      paddingRight: 20,
                    }}
                  >
                    {/* <AditionalActions
                      currentdata={dataList}
                      type={filterDatesListView.toLowerCase()}
                      currentWeek={filterDates}
                      allowEditActions={isUserPermissionJS}
                    /> */}
                    {isUserPermissionJS ? (
                      <AppMassPublishShiftButton shiftsList={dataList} loading={loadingData} />
                    ) : (
                      <></>
                    )}
                  </div>
                }
              />
            ) : viewType === 'activity' ? (
              <Activity
                sites={selectedSitesList}
                group={jobSchedulerGroup}
                allowEditActions={isUserPermissionJS}
              />
            ) : (
              <div></div>
            )}
          </div>
        )}
      </Formik>
      {/* {JSON.stringify(isFloatingButtonViewable)} viewableee */}
      {isUserPermissionJS && (
        <Menu
          align="end"
          direction="top"
          className="floatButton"
          menuButton={
            <DropDownWrapper className="floatButton">
              <FloatImg src={ICAddButton} style={{ cursor: 'pointer' }} />
            </DropDownWrapper>
          }
        >
          {/*<MenuItem>
            {`${t('JobScheduler.menu.add_multiple_shifts')}`}
          </MenuItem>*/}
          <MenuItem onClick={() => handleClickOpenCreateSnglShiftModal(SHIFT_CREATION)}>
            {`${t('JobScheduler.menu.add_single_shift')}`}
          </MenuItem>
          {/* <MenuItem onClick={() => handleClickOpenTemplatesModal()}>
            {`${t('JobScheduler.menu.add_from_template')}`}
          </MenuItem> */}
          {/* <MenuItem>
            {`${t('JobScheduler.menu.add_import_shifts')}`}
          </MenuItem>*/}
        </Menu>
      )}
      {/*Single shift*/}
      <AppModal
        open={openCreateSnglShift}
        handleclose={handleCloseCreateSnglShiftModal}
        title={shiftModalTitle}
        inside={1}
      >
        <ShiftForm
          handleclose={handleCloseCreateSnglShiftModal}
          setShowSucessModal={setShowSucessModal}
          action={shiftAction.current}
          shiftID={selectedShift.current}
          draft={draft}
        />
      </AppModal>
      {showSucessModal.show && (
        <CustomizedSnackbar
          show={showSucessModal.show}
          type={showSucessModal.type}
          title={showSucessModal.title}
          message={showSucessModal.message}
        />
      )}
      {/*Open Templates*/}
      <AppModal
        open={openTemplates}
        handleclose={handleClickCloseTemplatesModal}
        title={t('JobScheduler.modals.shift_templates_title')}
        inside={1}
        styleModal={{ position: 'absolute', bottom: '0px', right: '0px' }}
        style={{ marginBottom: '20px' }}
      >
        <ViewTemplates handleclose={handleClickCloseTemplatesModal} group={jobSchedulerGroup} />
      </AppModal>
      {/* Details */}
      <AppModal
        open={openShiftDetail}
        handleclose={() => handleShiftDetail(shift)}
        title={
          user && user.name
            ? user.name
            : `${i18next.t('form_labels.spots_left')}: ${originShift?.open_spots -
            originShift?.user_shifts?.filter((us) => us.status.value_definition !== '050')
              .length
            }/${originShift?.open_spots}`
        }
        subtitle={
          user && user.name
            ? `${i18next.t('form_labels.employee')}`
            : `${i18next.t('form_labels.open_shift')}`
        }
        icon={user ? user : avataraShiftDetail}
        inside={1}
      >
        {user &&
          userData &&
          userData.role &&
          userData.role.value_definition === '020' && //Site manager
          originShift?.user_shifts?.filter((us) => us.status.value_definition !== '050').length > 0 && //No declined status
          obtainUserShiftEmployee(shift, user)?.status?.value_definition === '030' ? ( //Pending for approval
          <EmployeeShiftView
            shift={shift}
            user={user}
            setOpenShiftDetail={setOpenShiftDetail}
            handleClickOpenCreateSnglShiftModal={handleClickOpenCreateSnglShiftModal}
            closeMainModal={() => setOpenShiftDetail(false)}
          />
        ) : (
          <GlobalView
            shift={originShift}
            user={user}
            handleclose={handleShiftDetail}
            setOpenShiftDetail={setOpenShiftDetail}
            handleClickOpenCreateSnglShiftModal={handleClickOpenCreateSnglShiftModal}
          />
        )}
      </AppModal>{' '}
    </>
  );
};
