import groovyWalkAnimation from '@assets/animations/93461-loading.json';
import ic_advance_filter_active from '@assets/icons/ic_advance_filter_active.svg';
import ic_advance_filter from '@assets/icons/ic_advance_filter.svg';
import ic_arrow_down from '@assets/icons/ic_arrow_down.svg';
import ic_arrow_left_enabled from '@assets/icons/ic_arrow_left_enabled.svg';
import ic_arrow_left from '@assets/icons/ic_arrow_left.svg';
import ic_arrow_right_enabled from '@assets/icons/ic_arrow_right_enabled.svg';
import ic_arrow_right from '@assets/icons/ic_arrow_right.svg';
import ic_caret_asc from '@assets/icons/ic_caret_asc.svg';
import ic_caret_default from '@assets/icons/ic_caret_default.svg';
import ic_caret_desc from '@assets/icons/ic_caret_desc.svg';
import ic_column_toggler from '@assets/icons/ic_column_toggler.svg';
import ic_remove from '@assets/icons/ic_remove.svg';
import ic_search from '@assets/icons/ic_search.svg';
import { InputForm } from '@components';
import { AppButton, AppDropDownCheckBoxSelect, CustomizedSnackbar } from '@components';
import { DEFAULT_PAGINATION_RESULTS_OPTIONS } from '@config';
import { PALETTE_COLOR } from '@config/CONSTANTS';
import { IconButton, Tooltip } from '@mui/material';
import Badge from '@mui/material/Badge';
import Collapse from '@mui/material/Collapse';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { getPropByString } from '@utils/appUtils';
import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { Scrollbars } from 'react-custom-scrollbars';
import DoubleScrollbar from 'react-double-scrollbar';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import styled from 'styled-components';
import * as XLSX from 'xlsx/xlsx.mjs';

import { getEntityData } from 'services';

//fix warning
LoadingOverlay.propTypes = undefined;

const MAIN_BLUE = PALETTE_COLOR.MAIN_BLUE;
const MAIN_WHITE = PALETTE_COLOR.MAIN_WHITE;

const TopBarWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #edf1f8;
  border-radius: 16px 16px 0px 0px;
  width: 100%;
  padding: ${(props) => (props.isStackedDetails ? '0px' : '20px')};
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const PaginationTopButton = styled.div`
  border: 1px solid #e7ecf4;
  box-sizing: border-box;
  border-radius: 20px;
  height: 40px;
  padding: 9.5px 20px 9.5px 20px;
  display: flex;
  font-size: 14px;
`;

const TableWrapper = styled.div`
  background: #ffffff;
`;

const ContainerWrapper = styled(Container)`
  &::-webkit-scrollbar {
    height: 8px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #e7ecf4;
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #e7ecf4;
  }
`;

const ArrowWrapper = styled.img`
  width: 20px;
  height: 22px;
`;

const PaginationTopButtonSeparator = styled.div`
  background: #e7ecf4;
  width: 1px;
  height: 24px;
  margin-left: 12px;
  margin-right: 12px;
`;

const BottomWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 0px #edf1f8;
  border-radius: 0px 0px 16px 16px;
  padding: 20px;
`;

const ResultsWrapper = styled.div`
  font-size: 14px;
  border: 1px solid #e7ecf4;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 8px 10px 8px 15px;
  width: 100%;
  display: flex;
  cursor: pointer;
`;

const AdvanceFilterButtonWrapper = styled.div`
  font-size: 14px;
  border: 1px solid #e7ecf4;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 8px 10px 8px 15px;
  display: flex;
  cursor: pointer;
`;

const PageLabelWrapper = styled.div`
  margin-left: 4px;
  margin-right: 4px;
`;

const StyledLoader = styled(LoadingOverlay)`
  .MyLoader_overlay {
    background: rgba(131, 180, 49, 0.2);
    color: #000;
  }
`;

const AdvanceFilterWrapper = styled(Collapse)`
  background: #ffffff;
  box-shadow: 0px 1px 0px #edf1f8;
  border-top: 1px solid #edf1f8;
`;

const AdvanceFilterWrapperInner = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 4px;
  flex-wrap: wrap;
`;

const IconWrapper = styled.img`
  width: 40px;
  height: 40px;
`;

const IconHeaderWrapper = styled.img`
  width: 25px;
  height: 40px;
  margin-right: 7px;
`;

const IconButtonWrapper = styled(IconButton)`
  width: 40px;
  height: 40px;
`;

const FilterWrapper = styled.div`
  background: #eff4fb;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 16px 5px;
  font-weight: 300;
  font-size: 14px;
  color: ${MAIN_BLUE};
`;

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 3,
    top: 6,
    border: `2px solid ${MAIN_WHITE}`,
    padding: '0 4px',
    background: 'red',
  },
}));

const StyledHeaderTitle = styled.div`
  font-size: 21px;
  font-weight: 400;
  padding: 5px 0px;
  margin-right: 25px;
  display: flex;
`;
const StyledHeaderSubTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  padding: 5px 0px;
  margin-right: 25px;
  display: flex;
`;

const AvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const AvatarTitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  margin-top: 10px;
  margin-left: 8px;
`;

const rowClasses = (row, rowIndex) => {
  let classes = 'appTable-row-even';
  //console.log(`rowIndex ${rowIndex}`);
  if (rowIndex % 2 === 0) {
    classes += ' appTable-row-odd';
  }

  return classes;
};

export const AppTable = (props) => {
  const { t } = useTranslation();
  const {
    columns,
    endpointPath,
    trailingBtn,
    idColumnName = 'id',
    order = 'DESC',
    filters,
    withAdvanceFilter = false,
    withColumnToggler = false,
    reload = false,
    data,
    filtersAdditional,
    disableFilters = false,
    disablePagination = false,
    disableTopPagination = false,
    noBorders = false,
    headerIcon,
    advanceFilterAlter = false,
    isSearchable = true,
    headerText,
    search_alter,
    joins,
    loading = false,
    withExport = false,
    exportFunction,
    allowOverFlowX = false,
    keyField = 'id',
    csvName = `export_${new Date().getTime()}`,
    avatar,
    avatarName,
    isStackedDetails,
    headerRow,
    overrideSearch,
    customSetSelectedFilters,
    testId,
    hasSubtitle = '',
    footerAdditional = ""
  } = props;
  const [apiData, setApiData] = useState(data ? { data: data } : {});
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState(['1-10']);
  const [currentPage, setCurrentPage] = useState('1-10');
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState(
    columns
      .filter((c) => c.text)
      .map((option) => ({
        label: option.text,
        value: option.dataField,
        isDisabled: option.disableColumnToggle,
      })),
  );
  //To handle message
  const [showMessage, setShowMessage] = useState({
    show: false,
    type: '',
    title: '',
    message: '',
  });
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [limit, setLimit] = useState(10);
  const [lastSortBy, setLastSortBy] = useState({
    field: idColumnName,
    order: order,
  });

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const calcPages = React.useCallback(
    async (pageCount, total) => {
      const newPages = [];
      for (let index = 0; index < pageCount; index++) {
        if (index + 1 === pageCount) {
          newPages.push(`${index * limit + 1}-${total}`);
        } else {
          newPages.push(`${index * limit + 1}-${(index + 1) * limit}`);
        }
      }
      setPages(newPages);
    },
    [limit],
  );

  useEffect(() => {
    setApiData(data ? { data: data } : {});
  }, [data]);

  useEffect(() => {
    if (endpointPath && reload) {
      setIsLoading(true);
      const qb = RequestQueryBuilder.create()
        .sortBy(lastSortBy)
        .setFilter(filters)
        .setLimit(limit)
        .setJoin(joins ? joins : [])
        .setPage(page)
        .search(search);
      getEntityData(endpointPath, `${qb.query()}`)
        .then((res) => {
          //console.log('AppTable > getEntityData > res=', res);
          const data = res;
          setApiData(data);
          setIsLoading(false);
          calcPages(data.pageCount, data.total);
          if (data.total < 10) {
            setCurrentPage(`1-${data.total}`);
          }
        })
        .catch((err) => {
          console.log(`${JSON.stringify(err)}`);
          setShowMessage({
            show: true,
            type: 'error',
            title: `${err.message}`,
            message: '',
          });
          setApiData({});
          setIsLoading(false);
        });
    }
    return () => { };
  }, [joins, lastSortBy, limit, page, search, filters, calcPages, endpointPath, reload]);

  // Effect for API call
  useEffect(
    () => {
      if (overrideSearch) {
        overrideSearch(debouncedSearchTerm);
      } else {
        if (debouncedSearchTerm) {
          const fieldsFiltering = [];
          columns.forEach((column) => {
            const isDummy = column.isDummyField && column.isDummyField === true;
            const isSearchable = column.search && column.search === true;
            if (!isDummy && isSearchable) {
              const fieldSearch = {};
              fieldSearch[column.dataField] = {
                [CondOperator.CONTAINS]: debouncedSearchTerm,
              };
              fieldsFiltering.push(fieldSearch);
            }
          });
          //fixedFilters
          const fieldsFixedFiltering = [];
          filters &&
            filters.forEach((filter) => {
              const fieldSearch = {};
              fieldSearch[filter.field] = { [filter.operator]: filter.value };
              fieldsFixedFiltering.push(fieldSearch);
            });
          fieldsFixedFiltering.push({ $or: fieldsFiltering });
          const s = { $and: fieldsFixedFiltering };
          setPage((page) => 1);
          setCurrentPage((currentPage) => pages[0]);
          setSearch(filters ? s : { $or: fieldsFiltering });
        } else {
          setSearch(null);
        }
      }
    },
    [debouncedSearchTerm, columns], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const calcColumns = () => {
    let fullColumns = columns.map((c) => ({
      ...c,
      onSort: (field, order) => {
        setPage(1);
        setLastSortBy({ field: field, order: order.toUpperCase() });
      },
    }));
    let visibleColumns = fullColumns.filter(
      (e) => selectedColumns.filter((se) => se.label === e.text).length > 0,
    );
    let dummyColumns = fullColumns.filter((e) => !e.text);
    return [...visibleColumns, ...dummyColumns];
  };

  const advanceFilterColumns = () => {
    return columns.filter((c) => c.advanceFilter !== undefined);
  };

  const sortOption = {
    // No need to configure sortFunc per column
    sortFunc: (a, b, order, dataField) => {
      //Disabled
    },
    // No need to configure sortCaret per column
    sortCaret: (order, column) => {
      if (!order)
        return (
          <span style={{ marginLeft: 5 }}>
            <img src={ic_caret_default} alt="down" />
          </span>
        );
      else if (order === 'asc')
        return (
          <span style={{ marginLeft: 5 }}>
            <img src={ic_caret_asc} alt="down" />
          </span>
        );
      else if (order === 'desc')
        return (
          <span style={{ marginLeft: 5 }}>
            <img src={ic_caret_desc} alt="down" />
          </span>
        );
      return null;
    },
  };

  const handleShowAdvanceFilter = () => {
    setShowAdvanceFilter((prev) => !prev);
  };

  const onFilterChange = (newSelectedOptions, event, selectedValues) => {
    if (event.action === 'select-option') {
      if (event.option.value.includes('*')) {
        handleAllSelections(event);
      } else {
        setSelectedFilters(selectedValues);
      }
    }
    if (event.action === 'deselect-option') {
      if (event.option.value.includes('*')) {
        handleAllSelections(event);
      } else {
        setSelectedFilters(
          selectedFilters.filter(
            (f) => f.filter === event.option.filter && f.value !== event.option.value,
          ),
        );
      }
    }
  };

  const handleAllSelections = (event) => {
    //console.log('🚀 ~ file: AppTable.js ~ line 438 ~ handleAllSelections ~ event', event);
    let filter = event.option.filter;
    let options = advanceFilterColumns().filter((o) => o.dataField === filter);
    if (selectedFilters.filter((sf) => sf.filter === filter).length > 0) {
      //UNSELECT
      setSelectedFilters(selectedFilters.filter((sf) => sf.filter !== filter));
    } else {
      //SELECT
      if (event.option.targetSelection && event.option.targetSelection.length > 0) {
        let fullArray = [
          ...selectedFilters.filter((sf) => sf.filter !== filter),
          ...options[0].advanceFilter
            .map((o) => ({ ...o, filter: filter }))
            .filter((o) => event.option.targetSelection.includes(o.label)),
          ...[event.option],
        ];
        setSelectedFilters(fullArray);
      } else {
        let fullArray = [
          ...selectedFilters.filter((sf) => sf.filter !== filter),
          ...options[0].advanceFilter.map((o) => ({ ...o, filter: filter })),
          ...[event.option],
        ];
        setSelectedFilters(fullArray);
      }
    }
  };

  const onColumnChange = (newSelectedOptions, event, selectedValues) => {
    if (event.action === 'select-option') {
      if (event.option.value.includes('*')) {
        setSelectedColumns(
          columns
            .filter((c) => c.text)
            .map((option) => ({
              label: option.text,
              value: option.dataField,
              isDisabled: option.disableColumnToggle,
            })),
        );
      } else {
        setSelectedColumns(
          selectedColumns.filter((f) => f.filter !== event.option.filter).concat(selectedValues),
        );
      }
    }
    if (event.action === 'deselect-option') {
      if (event.option.value.includes('*')) {
        setSelectedColumns(
          columns
            .filter((c) => c.text)
            .map((option) => ({
              label: option.text,
              value: option.dataField,
              isDisabled: option.disableColumnToggle,
            })),
        );
      } else {
        setSelectedColumns(
          selectedColumns.filter(
            (f) => f.filter === event.option.filter && f.value !== event.option.value,
          ),
        );
      }
    }
  };

  const exportData = () => {
    setIsLoading(true);
    const qb = RequestQueryBuilder.create()
      .select(columns.map((c) => c.dataField))
      .sortBy({ field: idColumnName, order: order })
      .setFilter(filters)
      .setJoin(joins ? joins : []);
    getEntityData(endpointPath, qb.query())
      .then((res) => {
        const transformedData = [];
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          const newObj = {};
          columns
            .filter((f) => !f.isDummyField)
            .forEach((column) => {
              if (column.dataField.includes('length')) {
                newObj[column.dataField] = element[column.dataField.replace('.length', '')].length;
              } else {
                newObj[column.dataField] = column.exportFormatter
                  ? column.exportFormatter(element)
                  : getPropByString(element, column.dataField);
              }
            });
          transformedData.push(newObj);
        }
        const worksheet = XLSX.utils.json_to_sheet(transformedData);
        // Process Data (add a new row)
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Dates');
        XLSX.utils.sheet_add_aoa(
          worksheet,
          [columns.filter((f) => !f.isDummyField).map((c) => c.text)],
          { origin: 'A1' },
        );
        XLSX.writeFile(workbook, `${csvName}.xlsx`);

        setIsLoading(false);
      })
      .catch((err) => {
        //Show message
        setShowMessage({
          show: true,
          type: 'error',
          title: `${err.message}`,
          message: '',
        });
        setApiData({});
        setIsLoading(false);
      });
  };

  // Effect for API call
  useEffect(
    () => {
      if (selectedFilters.length > 0) {
        const fieldsToAdd = selectedFilters.filter((f) => f.filter !== undefined);

        const groupedFilters = fieldsToAdd.reduce((groups, item) => {
          const group = groups[item.filter] || [];
          group.push(item);
          groups[item.filter] = group;
          return groups;
        }, {});
        const fieldsFinalFiltering = [];
        const keys = Object.keys(groupedFilters);
        keys.forEach((key) => {
          const fieldsFiltering = [];
          const fieldSearch = {};
          const baseColumn = columns.find((c) => c.dataField === key);
          fieldSearch[key] = baseColumn.customFilter
            ? baseColumn.customFilter(groupedFilters[key])
            : {
              [CondOperator.IN]: groupedFilters[key].map((f) => f.value),
            };
          fieldsFiltering.push(fieldSearch);

          fieldsFinalFiltering.push({ $and: fieldsFiltering });
        });
        //fixedFilters
        filters &&
          filters.forEach((filter) => {
            const fieldSearch = {};
            fieldSearch[filter.field] = {
              [Array.isArray(filter.value) ? filter.operator : CondOperator.EQUALS]: filter.value,
            };
            fieldsFinalFiltering.push(fieldSearch);
          });

        const s = { $and: fieldsFinalFiltering };
        setSearch(s);
      } else {
        setSearch(null);
      }
      customSetSelectedFilters?.(selectedFilters);
    },
    [selectedFilters], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <ContainerWrapper
        fluid
        data-testid={`${testId}_table`}
        style={noBorders ? { paddingLeft: 'unset', paddingRight: 'unset' } : {}}
      >
        <StyledLoader
          active={loading ? loading : isLoading}
          spinner={
            <Lottie
              animationData={groovyWalkAnimation}
              autoPlay={true}
              loop={true}
              style={{ width: 400 }}
            />
          }
          classNamePrefix="MyLoader_"
          text={t('AppTable.loading_content')}
        >
          {!disableFilters ? (
            <TopBarWrapper
              isStackedDetails={isStackedDetails}
              data-testid={`${testId}_table_topbar`}
              style={!isSearchable && hasSubtitle != "" ? { justifyContent: 'flex-end' } : {}}
            >
              {hasSubtitle != "" &&
                <StyledHeaderSubTitle>{hasSubtitle}</StyledHeaderSubTitle>
              }
              {avatar && avatarName ? (
                <AvatarWrapper>
                  {avatar}
                  <AvatarTitle>{avatarName}</AvatarTitle>
                </AvatarWrapper>
              ) : (
                <></>
              )}
              {headerText ? (
                <div
                  style={{
                    display: 'flex',
                    width: headerText.trim().length * 16,
                  }}
                >
                  <IconHeaderWrapper src={headerIcon} alt="icon" />
                  <StyledHeaderTitle>{headerText}</StyledHeaderTitle>
                </div>
              ) : null}
              <div style={{ display: 'flex', gap: 5 }}>
                {withAdvanceFilter ? (
                  <Tooltip title={t('AppTable.advanced_filter')} placement="bottom">
                    <IconButtonWrapper
                      style={{ width: 40, height: 40 }}
                      aria-label={t('AppTable.aria_organizations')}
                      onClick={handleShowAdvanceFilter}
                    >
                      <StyledBadge badgeContent={selectedFilters.length} color="success">
                        <IconWrapper
                          src={
                            showAdvanceFilter || selectedFilters.length > 0
                              ? ic_advance_filter_active
                              : ic_advance_filter
                          }
                          alt="Cap logo"
                        />
                      </StyledBadge>
                    </IconButtonWrapper>
                  </Tooltip>
                ) : null}
                {withColumnToggler ? (
                  <AppDropDownCheckBoxSelect
                    isSearchable={false}
                    allowSelectAll={true}
                    showSelection={false}
                    selectAllControlOption={{
                      label: `${t('AppTable.show_all_columns')}`,
                      value: '*',
                    }}
                    options={columns
                      .filter((c) => c.text)
                      .map((option) => ({
                        label: option.text,
                        value: option.dataField,
                        isDisabled: option.disableColumnToggle,
                      }))}
                    defaultValue={selectedColumns}
                    onChange={onColumnChange}
                    targetComponent={
                      <Tooltip title={t('AppTable.toggle_column')} placement="bottom">
                        <IconButtonWrapper
                          style={{ width: 40, height: 40 }}
                          aria-label={t('AppTable.aria_organizations_toggle_column')}
                        >
                          <IconWrapper src={ic_column_toggler} alt="Cap logo" />
                        </IconButtonWrapper>
                      </Tooltip>
                    }
                  />
                ) : null}
                {filtersAdditional && (
                  <>
                    {filtersAdditional.map((item, index) => {
                      return item.filter;
                    })}
                  </>
                )}

                {!disableTopPagination && (
                  <PaginationTopButton data-testid={`${testId}_table_pagination_top`}>
                    <div>{currentPage}</div>
                    <Menu
                      menuButton={
                        <ArrowWrapper
                          src={ic_arrow_down}
                          style={{ marginLeft: 5, cursor: 'pointer' }}
                          alt="IcArrowWrapper"
                        ></ArrowWrapper>
                      }
                      transition
                    >
                      <Scrollbars autoHeight>
                        {pages.map((v, i) => (
                          <MenuItem
                            key={i}
                            onClick={(e) => {
                              setPage(i + 1);
                              setLimit(limit);
                              setCurrentPage(v);
                            }}
                          >
                            {v}
                          </MenuItem>
                        ))}
                      </Scrollbars>
                    </Menu>

                    <PaginationTopButtonSeparator />
                    <div style={{ color: '#878E97', marginRight: 5 }}>{t('AppTable.of')}</div>
                    {apiData?.total}
                    <IconButton
                      aria-label={t('AppTable.aria_job_scheduler')}
                      style={{ marginLeft: 5 }}
                      disabled={page === 1}
                      onClick={(e) => {
                        setCurrentPage(pages[page - 2]);
                        setPage(page - 1);
                      }}
                    >
                      <ArrowWrapper
                        style={{ height: 10, width: 5 }}
                        src={page !== 1 ? ic_arrow_left_enabled : ic_arrow_left}
                        alt="IcArrowLeft"
                      ></ArrowWrapper>
                    </IconButton>
                    <IconButton
                      aria-label={t('AppTable.aria_job_scheduler')}
                      disabled={page === apiData.pageCount}
                      onClick={(e) => {
                        setCurrentPage(pages[page]);
                        setPage(page + 1);
                      }}
                    >
                      <ArrowWrapper
                        style={{ height: 10, width: 5 }}
                        src={page === apiData.pageCount ? ic_arrow_right : ic_arrow_right_enabled}
                        alt="IcArrowLeft"
                      ></ArrowWrapper>
                    </IconButton>
                  </PaginationTopButton>
                )}
              </div>
              {isSearchable && (
                <InputForm
                  mb={'0px'}
                  style={{ flexGrow: 1 }}
                  height={'40px'}
                  placeholder={t('placeholder_search')}
                  icon={ic_search}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  testId={`${testId}_table_search`}
                />
              )}
              {search_alter && search_alter}
              {withExport ? (
                <AppButton
                  size="lg"
                  bgcolor={PALETTE_COLOR.MAIN_WHITE}
                  bghovercolor={PALETTE_COLOR.MAIN_BLUE}
                  color={PALETTE_COLOR.MAIN_BLUE}
                  bordercolor={PALETTE_COLOR.TOP_GRAY}
                  borderhovercolor={PALETTE_COLOR.TOP_GRAY}
                  style={{
                    fontSize: isStackedDetails && '14px',
                    marginRight: isStackedDetails && '8px',
                    marginLeft: isStackedDetails && '8px',
                  }}
                  onClick={exportFunction ?? exportData}
                  testId={`${testId}_table_export`}
                >
                  {t('AppTable.export')}
                </AppButton>
              ) : null}
              {trailingBtn}
            </TopBarWrapper>
          ) : null}
          {withAdvanceFilter ? (
            <AdvanceFilterWrapper in={showAdvanceFilter}>
              <AdvanceFilterWrapperInner>
                {advanceFilterColumns().map((c, index) => (
                  <>
                    <AppDropDownCheckBoxSelect
                      key={index}
                      showSelection={false}
                      options={c.advanceFilter.map((d) => ({
                        ...d,
                        filter: c.dataField,
                      }))}
                      isSearchable={false}
                      allowSelectAll={true}
                      height={300}
                      width={300}
                      onChange={onFilterChange}
                      selectAllControlOption={{
                        label: `${t('lbl_all')}`,
                        value: '*',
                        filter: c.dataField,
                      }}
                      defaultValue={selectedFilters}
                      targetComponent={
                        <AdvanceFilterButtonWrapper>
                          {c.text}
                          <ArrowWrapper
                            src={ic_arrow_down}
                            style={{ marginLeft: 5 }}
                            alt="IcArrowWrapper"
                          ></ArrowWrapper>
                        </AdvanceFilterButtonWrapper>
                      }
                    />
                    {selectedFilters.filter(
                      (f) => f.filter === c.dataField && !f.value.includes('*'),
                    ).length >= c.advanceFilter.length ? (
                      <FilterWrapper>{t('lbl_all')}</FilterWrapper>
                    ) : null}

                    {selectedFilters.filter(
                      (f) => f.filter === c.dataField && !f.value.includes('*'),
                    ).length < c.advanceFilter.length &&
                      selectedFilters
                        .filter((f) => f.filter === c.dataField && !f.value.includes('*'))
                        .map((filter, i) => (
                          <FilterWrapper key={i}>
                            {filter.label}
                            <IconButton
                              aria-label={t('AppTable.remove_filter')}
                              onClick={() => {
                                selectedFilters.splice(selectedFilters.indexOf(filter), 1);
                                setSelectedFilters([...selectedFilters]);
                              }}
                            >
                              <IconWrapper
                                style={{ width: 8, height: 8 }}
                                src={ic_remove}
                                alt="remove"
                              />
                            </IconButton>
                          </FilterWrapper>
                        ))}
                  </>
                ))}
                {selectedFilters.length > 0 ? (
                  <AdvanceFilterButtonWrapper
                    style={{ cursor: 'pointer', color: MAIN_BLUE }}
                    onClick={(e) => setSelectedFilters([])}
                  >
                    {t('AppTable.reset_all')}
                  </AdvanceFilterButtonWrapper>
                ) : null}
              </AdvanceFilterWrapperInner>
            </AdvanceFilterWrapper>
          ) : (
            <></>
          )}
          {!loading && headerRow}
          {advanceFilterAlter ? advanceFilterAlter : <></>}
          <ConditionalWrapper
            condition={allowOverFlowX}
            wrapper={(children) => (
              <div style={{ backgroundColor: 'white' }}>
                <DoubleScrollbar>{children}</DoubleScrollbar>
              </div>
            )}
            falseWrapper={(children) => (
              <TableWrapper
                style={
                  noBorders
                    ? { borderRadius: '16px', borderColor: 'transparent' }
                    : allowOverFlowX
                      ? { overflowX: 'scroll' }
                      : {}
                }
              >
                {children}
              </TableWrapper>
            )}
          >
            <BootstrapTable
              bootstrap4
              rowClasses={rowClasses}
              classes={`appTable appTable-row ${noBorders ? 'appTableRounded' : ''}`}
              keyField={keyField}
              data={apiData.data ? apiData.data : []}
              columns={calcColumns()}
              sort={sortOption}
              headerClasses="app-table-header-class"
            />
          </ConditionalWrapper>
          {!disablePagination ? (
            <BottomWrapper data-testid={`${testId}_table_pagination_bottom`}>
              <Row>
                <Col
                  xl={2}
                  lg={2}
                  style={{ display: 'flex' }}
                  data-testid={`${testId}_table_pagination`}
                >
                  <PageLabelWrapper>{t('AppTable.page')}</PageLabelWrapper>
                  <PageLabelWrapper style={{ fontWeight: 'bold' }}>
                    {apiData?.page}
                  </PageLabelWrapper>
                  <PageLabelWrapper>{t('AppTable.of')}</PageLabelWrapper>
                  <PageLabelWrapper style={{ fontWeight: 'bold' }}>
                    {apiData?.pageCount}
                  </PageLabelWrapper>
                </Col>
                <Col
                  xl={7}
                  lg={7}
                  style={{ alignItems: 'center', textAlign: 'center' }}
                  data-testid={`${testId}_table_pagination_controls`}
                >
                  <IconButton
                    aria-label={t('AppTable.aria_job_scheduler')}
                    disabled={page === 1}
                    onClick={(e) => {
                      setCurrentPage(pages[page - 2]);
                      setPage(page - 1);
                    }}
                  >
                    <ArrowWrapper
                      style={{ height: 10, width: 10, marginTop: 5 }}
                      src={page !== 1 ? ic_arrow_left_enabled : ic_arrow_left}
                      alt="IcArrowLeft"
                    ></ArrowWrapper>
                  </IconButton>
                  <IconButton
                    aria-label={t('AppTable.aria_job_scheduler')}
                    style={{ marginLeft: 30 }}
                    disabled={page === apiData.pageCount}
                    onClick={(e) => {
                      setCurrentPage(pages[page]);
                      setPage(page + 1);
                    }}
                  >
                    <ArrowWrapper
                      style={{ height: 10, width: 10, marginTop: 5 }}
                      src={page === apiData.pageCount ? ic_arrow_right : ic_arrow_right_enabled}
                      alt="IcArrowLeft"
                    ></ArrowWrapper>
                  </IconButton>
                </Col>
                <Col
                  xl={3}
                  lg={3}
                  style={{ display: 'flex' }}
                  data-testid={`${testId}_table_pagination_results_per_page`}
                >
                  <Menu
                    menuButton={
                      <ResultsWrapper>
                        {t('AppTable.results_page')}
                        <div
                          style={{
                            fontWeight: 'bold',
                            marginLeft: 5,
                            position: 'absolute',
                            right: '50px',
                          }}
                        >
                          {limit}
                        </div>
                        <ArrowWrapper
                          src={ic_arrow_down}
                          style={{ marginLeft: 5, position: 'absolute', right: '25px' }}
                          alt="IcArrowWrapper"
                        ></ArrowWrapper>
                      </ResultsWrapper>
                    }
                    transition
                  >
                    {DEFAULT_PAGINATION_RESULTS_OPTIONS.map((v, i) => (
                      <MenuItem
                        key={i}
                        onClick={(e) => {
                          setPage(1);
                          setLimit(v);
                          setCurrentPage(`1-${v}`);
                        }}
                      >
                        {v}
                      </MenuItem>
                    ))}
                  </Menu>
                </Col>
              </Row>
            </BottomWrapper>
          ) : null}
          
          {footerAdditional && (
                  <>
                    {footerAdditional.map((item, index) => {
                      return item.footer;
                    })}
                  </>
                )}
          {showMessage.show && (
            <CustomizedSnackbar
              show={showMessage.show}
              type={showMessage.type}
              title={showMessage.title}
              message={showMessage.message}
            />
          )}
        </StyledLoader>
      </ContainerWrapper>
    </>
  );

  // Hook
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay], // Only re-call effect if value or delay changes
    );
    return debouncedValue;
  }
};

const ConditionalWrapper = ({ condition, wrapper, falseWrapper, children }) =>
  condition ? wrapper(children) : falseWrapper(children);
