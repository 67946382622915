import moment from 'moment';

export const ShortTimeZone = (timeZone) => {
  switch (timeZone?.value_definition) {
    case '008':
      return 'Kwajalein';
    case '009':
      return 'Midway Island, Samoa';
    case '010':
      return 'US Hawaii';
    case '011':
      return 'Taiohae';
    case '020':
      return 'US Alaska';
    case '030':
      return 'US Pacific';
    case '040':
      return 'US Mountain';
    case '050':
      return 'US Central';
    case '060':
      return 'America/Bogota';
    case '070':
      return 'Caracas';
    default:
      return 'America/Bogota';
  }
};

export const ShiftTimeDifferences = (shift) => {
  return ShiftEndHour(shift) - ShiftStartHour(shift);
};

export const convertDatetoTime = (isoDate) => {
  const date = new Date(isoDate);

  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  
  // Formatear la hora en formato de 12 horas
  const formattedTime = `${((hours + 11) % 12 + 1)}:${minutes.toString().padStart(2, '0')} ${hours >= 12 ? 'PM' : 'AM'}`;
  
  return formattedTime;
};

export const ShiftFullTimeDifferences = (shift) => {
  // const start_time = shift.start_time;
  // const end_time = shift.end_time;
  const startHour = parseInt(moment.utc(shift.start_time).format('HH'));
  const endHour = parseInt(moment.utc(shift.end_time).format('HH'));
  if (
    moment.utc(shift.start_time).format('HH:mm a') === moment.utc(shift.end_time).format('HH:mm a')
  )
    return 24;

  if (moment.utc(shift.start_time).format('DD') < moment.utc(shift.end_time).format('DD')) {
    return moment
      .utc(shift.end_time)
      .diff(shift.start_time, 'hours', true)
      .toFixed(1)
      .replace(/[.,]0$/, '');
  }

  let startTime = moment.utc(shift.shift_date).set({
    hour: startHour,
    minute: parseInt(moment.utc(shift.start_time).format('mm')),
    second: 0,
  });
  let endTime = moment.utc(shift.shift_date).set({
    hour: endHour,
    minute: parseInt(moment.utc(shift.end_time).format('mm')),
    second: 0,
  });
  if (startTime > endTime) {
    let nextTime = moment.utc(endTime).add(1, 'days');
    return moment
      .utc(nextTime)
      .diff(startTime, 'hours', true)
      .toFixed(1)
      .replace(/[.,]0$/, '');
  }
  return moment
    .utc(endTime)
    .diff(startTime, 'hours', true)
    .toFixed(1)
    .replace(/[.,]0$/, '');
};

export const ShiftFullTimeDifferencesHhMm = (shift) => {
  const startHour = parseInt(moment.utc(shift.start_time).format('HH'));
  const endHour = parseInt(moment.utc(shift.end_time).format('HH'));
  if (
    moment.utc(shift.start_time).format('HH:mm a') === moment.utc(shift.end_time).format('HH:mm a')
  )
    return 24;

  let startTime = moment.utc(shift.shift_date).set({
    hour: startHour,
    minute: parseInt(moment.utc(shift.start_time).format('mm')),
    second: 0,
  });
  let endTime = moment.utc(shift.end_time).set({
    hour: endHour,
    minute: parseInt(moment.utc(shift.end_time).format('mm')),
    second: 0,
  });
  if (startTime > endTime) {
    let nextTime = moment.utc(endTime).add(1, 'days');
    return moment.utc(nextTime).diff(startTime, 'hours');
  }
  let difHours = '' + moment.utc(endTime).diff(startTime, 'hours');
  let difMin = '' + (moment.utc(endTime).diff(startTime, 'minutes') % 60);
  return `${difHours}:${difMin.padStart(2, '0')}`;
};

export const ShiftStartHour = (shift) => {
  return parseInt(moment.utc(shift.start_time).format('HH'));
};

export const ShiftEndHour = (shift) => {
  let startHour = ShiftStartHour(shift);
  let endHour = parseInt(moment.utc(shift.end_time).format('HH'));
  endHour = endHour === 0 ? 24 : endHour;
  endHour = endHour <= startHour ? 24 : endHour;
  return endHour;
};

export const ShiftTimes = (shift) => {
  let startMinutes = moment.utc(shift.start_time).format('mm');
  let endMinutes = moment.utc(shift.end_time).format('mm');
  let time = `${moment.utc(shift.start_time).format('h')}${startMinutes === '00' ? '' : `:${startMinutes}`
    }${moment.utc(shift.start_time).format('a') === 'am' ? 'a' : 'p'} - ${moment
      .utc(shift.end_time)
      .format('h')}${endMinutes === '00' ? '' : `:${endMinutes}`}${moment.utc(shift.end_time).format('a') === 'am' ? 'a' : 'p'
    }`;
  return time;
};

export const ShiftDate = (shift) => {
  let date = `${moment.utc(shift.shift_date).format('MMMM D, YYYY')}`;
  return date;
};

export const CalcDuration = (minutes) => {
  return `${('' + parseInt(minutes / 60)).padStart(2, '0')}:${('' + (minutes % 60)).padStart(
    2,
    '0',
  )}`;
};
export const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
};

export const calcHoursByMinutes = (mins) => {
  if (!mins) return '00:00';
  let hours = Math.floor(mins / 60);
  let minutes = mins % 60;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
};

export const CalcHoursDuration = (minutes) => {
  if (!minutes) return '0h';
  return `${parseInt(minutes / 60)}h`;
};

export const calcDuration = (mins) => {
  if (!mins) return '0h';
  let hours = Math.floor(mins / 60);
  let minutes = mins % 60;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}h ${minutes}m`;
};

export const getPropByString = (obj, propString) => {
  if (!propString) return obj;

  var prop,
    props = propString.split('.');

  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    var candidate = obj[prop];
    if (candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }
  return obj[props[i]];
};

export const calcDurationHours = (mins) => {
  if (!mins) return '0h';
  let hours = Math.floor(mins / 60);
  let minutes = mins % 60;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}h${minutes % 60}m`;
};

export const calculateTimeHours = (shift) => {
  const startHour = parseInt(moment.utc(shift.start_time).format('HH'));
  const endHour = parseInt(moment.utc(shift.end_time).format('HH'));
  let difHours = '';
  let difMin = '';
  if (
    moment.utc(shift.start_time).format('HH:mm a') === moment.utc(shift.end_time).format('HH:mm a')
  ) {
    difHours = '24';
    difMin = '00';
  } else {
    let startTime = moment.utc(shift.shift_date).set({
      hour: startHour,
      minute: parseInt(moment.utc(shift.start_time).format('mm')),
      second: 0,
    });
    let endTime = moment.utc(shift.end_date).set({
      hour: endHour,
      minute: parseInt(moment.utc(shift.end_time).format('mm')),
      second: 0,
    });
    console.log(startTime);
    console.log(endTime);

    if (startTime > endTime) {
      let nextTime = moment.utc(endTime).add(1, 'days');
      difHours = '' + moment.utc(nextTime).diff(startTime, 'hours');
      difMin = '' + (moment.utc(nextTime).diff(startTime, 'minutes') % 60);
    } else {
      difHours = '' + moment.utc(endTime).diff(startTime, 'hours');
      difMin = '' + (moment.utc(endTime).diff(startTime, 'minutes') % 60);
    }
  }

  let timeInit = `${padTo2Digits(difHours)}:${difMin.padStart(2, '0')}`;

  console.log('Time Init', timeInit);
  console.log('Break', calcHoursByMinutes(shift.breakTime));
  let durationInit = '';
  if (difHours === '24') {
    durationInit = 1440;
  } else {
    durationInit = moment.duration(moment(timeInit, 'HH:mm').format('HH:mm')).asMinutes();
  }
  let durationbreak = moment
    .duration(moment(calcHoursByMinutes(shift.breakTime), 'HH:mm').format('HH:mm'))
    .asMinutes();
  console.log('Time Init Duration', durationInit);
  console.log('Duration Break', durationbreak);
  let duration_total = parseFloat(durationInit) - parseFloat(durationbreak);
  console.log('Duration Total', duration_total);
  console.log('Duration Total HH', calcDurationHours(duration_total));

  let arr = {
    time_init: durationInit,
    time_total: duration_total,
    horas_break: calcDurationHours(duration_total),
  };
  return arr;
};

export const getTimeZone = () => {
  var offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  const response =
    (offset < 0 ? '+' : '-') +
    ('00' + Math.floor(o / 60)).slice(-2) +
    ':' +
    ('00' + (o % 60)).slice(-2);
  return response.startsWith('-0') || response.startsWith('+0')
    ? `${response.split(':')[0].replace('0', '')}:${response.split(':')[1]}`
    : response;
};
