
import { AppDarkTooltip, AppOrgIcon, AppTable } from '@components';
import { CustomizedSnackbar } from '@components/CustomizedSnackbar';
import { USER_LOCATIONS_API } from '@services/CONSTANTS';
import React from 'react';
import { formatEspecial } from '@utils/dateFormatHelper';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CondOperator } from '@nestjsx/crud-request';
import { calcAvatar } from 'utils/avatar';
import { convertDatetoTime } from 'utils/appUtils';
import EncList from '@components/EncList';
import Timesheet_header from '@assets/icons/Timesheet_header.svg';


const StyledLabel = styled.div`
  margin-left: 4px;
  padding: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  font-family: 'Poppins';
  display: flex;
`;

const StyledGroups = styled.div`
  width: auto;
  margin: 0px;
  padding: 10px 10px;
  background:  #E8FAF7;
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
`;

export const MonitoringList = () => {
  const { t } = useTranslation();
  const [showSucessModal, setShowSucessModal] = React.useState({
    show: true,
    type: '',
    title: '',
    message: '',
  });

  //Joins
  const joins = React.useMemo(() => {
    return [
      { field: 'userShift' },
      { field: 'userShift.user' },
      { field: 'userShift.shift' },
      { field: 'userShift.shift.site' },
      { field: 'userShift.shift.site.groupsSite' },
      { field: 'userShift.shift.site.groupsSite.group' }
    ];
  }, []);

  let history = useHistory();

  /***
   *
   */
  let goTo = (route) => {
    history.push(route);
  };


  const columns = [
    {
      dataField: 'id',
      text: `${t('table_columns.ID')}`,
      disableColumnToggle: true,
      headerStyle: { width: '15%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <AppDarkTooltip title={`${row.id}`}>
            <StyledLabel
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
              }}
            >{row.userShift.user.employee_id}
            </StyledLabel>
          </AppDarkTooltip>
        );
      },
    },
    {
      dataField: 'name',
      text: `${t('table_columns.full_name')}`,
      disableColumnToggle: true,
      headerStyle: { width: '15%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <AppDarkTooltip title={`${row.userShift.user.name}`}>
            <StyledLabel
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
              }}
            >
              {calcAvatar(row.userShift.user)}
              <span style={{ marginLeft: 10 }}>{row.userShift.user.name}</span>
            </StyledLabel>
          </AppDarkTooltip>
        );
      },
    },
    {
      dataField: 'title',
      text: `${t('JobScheduler.modals.shift_title')}`,
      disableColumnToggle: true,
      headerStyle: { width: '15%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <AppDarkTooltip title={`${row.userShift.shift.title}`}>
            <StyledLabel
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
              }}
            >
              {row.userShift.shift.title}
            </StyledLabel>
          </AppDarkTooltip>
        );
      },
    },

    {
      dataField: 'site',
      text: `${t('JobScheduler.activities.site')}`,
      disableColumnToggle: true,
      headerStyle: { width: '15%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <AppDarkTooltip title={`${row.userShift.shift.site.name}`}>
            <StyledGroups key={row}>{row.userShift.shift.site.name}</StyledGroups>
          </AppDarkTooltip>
        );
      },
    },
    {
      dataField: 'group',
      text: `${t('JobScheduler.activities.group_site')}`,
      disableColumnToggle: true,
      headerStyle: { width: '15%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        const filteredList = [
          ...new Set(row.userShift.shift.site.groupsSite?.map((item) => item.group?.name)),
        ];
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {filteredList
              .filter((el) => el !== undefined)
              .map((group, index) => (
                <StyledGroups key={index}>{group}</StyledGroups>
              ))}
          </div>
        );
      },
    },
    {
      dataField: 'date',
      text: `${t('form_labels.shift_date')}`,
      disableColumnToggle: true,
      headerStyle: { width: '15%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <AppDarkTooltip title={`${formatEspecial(row.userShift.shift.shift_date, 'ddd D.MM')}`}>
            <StyledLabel
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
              }}
            >
              {formatEspecial(row.userShift.shift.shift_date, 'ddd') +
                ' ' +
                row.userShift.shift.shift_date.substring(8, 10) +
                '.' +
                formatEspecial(row.userShift.shift.shift_date, 'MM')}{' '}
            </StyledLabel>
          </AppDarkTooltip>
        );
      },
    },
    {
      dataField: 'start_time',
      text: `${t('table_columns.start_time')}`,
      disableColumnToggle: true,
      headerStyle: { width: '15%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <AppDarkTooltip title={`${row.userShift.shift.start_time}`}>
            <StyledLabel
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
              }}
            >
              {convertDatetoTime(row.userShift.shift.start_time)}
            </StyledLabel>
          </AppDarkTooltip>
        );
      },
    },
    {
      dataField: 'end_time',
      text: `${t('table_columns.end_time')}`,
      disableColumnToggle: true,
      headerStyle: { width: '15%' },
      style: { height: '54px' },
      formatter: (cellContent, row) => {
        return (
          <AppDarkTooltip title={`${row.userShift.shift.end_time}`}>
            <StyledLabel
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
              }}
            >
              {convertDatetoTime(row.userShift.shift.end_time)}
            </StyledLabel>
          </AppDarkTooltip>
        );
      },
    }
  ];

  /////////////////////////////////////////////////////////////////////////////////
  const DateTimeDisplay = () => {
    const now = new Date();

    // Formateo manual de la fecha
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Los meses empiezan en 0
    const year = now.getFullYear();

    // Formateo manual de la hora
    let hours = now.getHours();
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // La hora "0" se convierte a "12"

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;

    return formattedDateTime;
  };
  return (
    <div>
      <EncList
        title={t('JobScheduler.monitoring.title')}
        icon={Timesheet_header}
      ></EncList>
      <AppTable
        hasSubtitle={t('form_labels.report_date') + DateTimeDisplay()}
        testId={'monitoringList'}
        endpointPath={USER_LOCATIONS_API}
        joins={joins}
        filters={{
          field: 'arrivingLate',
          operator: CondOperator.EQUALS,
          value: 1,
        }}
        columns={columns}
        reload={showSucessModal.show}
        withExport={false}
        isSearchable={false}
      />
      {showSucessModal.show && showSucessModal.type && (
        <CustomizedSnackbar
          show={showSucessModal.show}
          type={showSucessModal.type}
          title={showSucessModal.title}
          message={showSucessModal.message}
        />
      )}
    </div>
  );
};
